import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/styles';
import 'vuetify/dist/vuetify.min.css';
import { createVuetify } from 'vuetify';
import { aliases, md } from 'vuetify/iconsets/md';
import { mdi } from 'vuetify/iconsets/mdi';
import colors from 'vuetify/lib/util/colors.mjs';
import { ja } from 'vuetify/lib/locale/index.mjs';
export default createVuetify({
  locale: {
    locale: 'ja',
    fallback: 'ja',
    messages: { ja },
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: process.env.VUE_APP_MODE === 'production' ? colors.orange.darken3 : colors.orange.darken3,
          secondary: colors.blue.darken3, // #F57C00
          accent: colors.red.darken2, // #388E3C
          error: colors.red.lighten1, // #EF5350
          warning: colors.yellow.accent4, // #FFD600
          info: colors.purple.base, // #9C27B0
          message: colors.yellow.base,
          success: colors.green.base, // #4CAF50
          background: colors.grey.lighten5,
        },
      },
    },
  },
});
