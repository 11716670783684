<template>
  <v-dialog v-model="display" max-width="750px">
    <v-card class="scroll-none">
      <v-card-title class="text-h5 font-weight-bold"> {{ titleType }}登録 </v-card-title>
      <v-card-text style="height: 100%" class="py-6">
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="3"> <v-list-subheader class="text-black"> タイトル </v-list-subheader></v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="title" :rules="rules.title" variant="outlined" density="compact" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-list-subheader class="text-black"> {{ titleType }}ファイル </v-list-subheader></v-col
            >
            <v-col cols="12" sm="8">
              <v-file-input
                v-model="file"
                :rules="rules.required"
                :accept="acceptType"
                label="ファイルを選択"
                variant="solo-filled"
                density="compact"
                hide-details="auto"
              />
              <p class="text-body-2 text-red pt-3">{{ `拡張子が「${acceptType}」のファイルを指定してください` }}</p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col cols="4" sm="2" offset="4" offset-sm="8" class="pt-4">
            <v-btn block variant="outlined" @click="onPushCancelBtn">閉じる</v-btn>
          </v-col>
          <v-col cols="4" sm="2" class="pt-4">
            <v-btn color="primary" variant="elevated" block @click="onPushEnterBtn"><v-icon>done</v-icon>保存</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { nextTick } from 'vue';
import { Prop, Ref } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { Formattable } from '@/common/mixin/formattable';
import { CommonSettings } from '@/common/common-settings';
import * as Utils from '@/common/utils';
import { Media } from '@/store/models/media';
import { VForm } from 'vuetify/lib/components/index.mjs';

/** Interface **/
export interface AddMediaDialogParams {
  title: string;
  src: string;
  file: File;
}

@Options({})
export default class AddMediaDialog extends Formattable {
  /** Data **/
  @Prop({ default: '画像' }) titleType!: string;
  @Prop({ default: '.png, .jpg, .jpeg' }) acceptType!: string;

  /** Refs **/
  @Ref() readonly form!: InstanceType<typeof VForm>;

  title = '';
  file: File | null = null;
  display = false;

  rules: { [key: string]: Array<(key) => boolean | string> } = {
    required: [(value: File[] | undefined): boolean | string => !value || value.length > 0 || '必須項目です。'],
    title: [
      (value: string): boolean | string => value !== '' || '必須項目です。',
      (value: string): boolean | string =>
        Utils.maxLength(value, Media.MAX_MEDIA_TITLE_LENGTH) ||
        `${Media.MAX_MEDIA_TITLE_LENGTH}文字以内で入力してください。`,
    ],
  };

  /** Methods **/
  open(): void {
    this.display = true;
  }

  close(): void {
    this.title = '';
    this.file = null;
    this.display = false;
  }

  onPushCancelBtn(): void {
    this.close();
  }

  async onPushEnterBtn(): Promise<void> {
    // DOMの更新を待つ
    await nextTick();

    const validation = await this.form.validate();

    if (!validation.valid || !this.file) {
      alert(validation.errors[0]?.errorMessages?.[0] ?? `入力項目に不足または誤りがあります`);
      return;
    }

    const fileName = Utils.makeNewUuid();
    const extension = this.file ? this.file.name.split('.').pop() : '';
    const param: AddMediaDialogParams = {
      title: this.title,
      src: Utils.getStorageFilePath({
        is2ndGenData: this.is2ndGenData,
        folderName: CommonSettings.STORAGE.MEDIA_IMAGES,
        contractId: this.contractId,
        storeId: this.storeId,
        fileName: `${fileName}.${extension}`,
      }),
      file: this.file,
    };

    this.$emit('onPushEnterBtn', param);
    this.close();
  }
}
</script>
<style scoped>
.scroll-none {
  overflow: hidden !important;
}
</style>
