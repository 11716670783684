import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { EnvConfig } from '../common/env-config';
import { getAuth } from 'firebase/auth';
import { FirebaseApp, FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig: FirebaseOptions = {
  apiKey: EnvConfig.firebase.API_KEY,
  authDomain: EnvConfig.firebase.AUTH_DOMAIN,
  databaseURL: EnvConfig.firebase.DATABASE_URL,
  projectId: EnvConfig.firebase.PROJECT_ID,
  storageBucket: EnvConfig.firebase.STORAGE_BUCKET,
  messagingSenderId: EnvConfig.firebase.MESSAGING_SENDER_ID,
  appId: EnvConfig.firebase.FIREBASE_APP_ID,
};

export const firebaseApp: FirebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Exrorts
// export { User } from 'firebase';
export const db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp, EnvConfig.firebase.STORAGE_BUCKET);
export const functions = getFunctions(firebaseApp, EnvConfig.firebase.FUNCTIONS_REGION);
