<template>
  <v-overlay :model-value="display" class="align-center justify-center overlay-index" persistent>
    <div class="text-center">
      <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
    </div>
    <div class="mt-2 text-white text-center font-weight-bold">{{ message ? message : '' }}</div>
  </v-overlay>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class LoadingDialog extends Vue {
  /** Props **/
  @Prop({ default: false }) display!: boolean;
  @Prop({ default: '' }) message!: string;
}
</script>
<style scoped>
.overlay-index {
  z-index: 99999 !important;
}
</style>
