import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { StoreError } from '@/common/error';
import { api } from '@/firebase/api';
import {
  AddCouponReq,
  DeleteCouponReq,
  GetCouponReq,
  GetCouponRes,
  UpdateCouponReq,
  UpdateUsageCountReq,
  UpdateCouponParam,
  AddCoupon,
} from '@/firebase/dto/coupon';
import { CouponListItem } from '@/store/models/db/common-models';
import { Coupon } from '@/store/models/db/log-models';

export const useCouponStore = defineStore('coupon', () => {
  // const userStore = useUsersStore();

  // stateはref変数
  const couponListItems = ref([]) as Ref<CouponListItem[]>;

  // gettterはcomputed
  const couponList = computed(() => couponListItems.value);

  // actionはmutationと統合して、関数
  /**
   * rCouponsを取得する
   *
   * @param obj
   */
  const fetchCoupons = async (obj: { contractId: string; liffAccessToken?: string }): Promise<void> => {
    try {
      const res = await api.coupon<GetCouponReq, GetCouponRes>({
        process: 'get',
        contractId: obj.contractId,
        liffAccessToken: obj.liffAccessToken,
      });

      couponListItems.value = res.coupons.sort((a, b) => {
        if (a.dataSource !== b.dataSource) {
          return b.dataSource ? 1 : -1;
        }

        // startDateを比較（降順）
        if (a.startDate && b.startDate) {
          if (a.startDate > b.startDate) return -1;
          if (a.startDate < b.startDate) return 1;
        } else if (a.startDate) {
          return -1;
        } else if (b.startDate) {
          return 1;
        }

        // endDateを比較（降順）
        if (a.endDate && b.endDate) {
          if (a.endDate > b.endDate) return -1;
          if (a.endDate < b.endDate) return 1;
        } else if (a.endDate) {
          return -1;
        } else if (b.endDate) {
          return 1;
        }

        return 0;
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rCouponsを追加する
   *
   * @param obj
   */
  const addCoupon = async (obj: { contractId: string; coupon: AddCoupon }): Promise<void> => {
    try {
      await api.coupon<AddCouponReq, void>({
        process: 'add',
        contractId: obj.contractId,
        coupon: obj.coupon,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rCouponsを更新する
   *
   * @param obj
   */
  const updateCoupon = async (obj: { contractId: string; coupon: UpdateCouponParam }): Promise<void> => {
    try {
      await api.coupon<UpdateCouponReq, void>({
        process: 'update',
        contractId: obj.contractId,
        coupon: obj.coupon,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * クーポンの利用回数を更新する
   *
   * @param obj
   */
  const updateUsageCount = async (obj: {
    contractId: string;
    liffAccessToken: string;
    coupon: Coupon;
  }): Promise<void> => {
    try {
      await api.coupon<UpdateUsageCountReq, void>({
        process: 'update_usage_count',
        contractId: obj.contractId,
        liffAccessToken: obj.liffAccessToken,
        useCoupon: obj.coupon,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rCouponsを削除する
   *
   * @param obj
   */
  const deleteCoupon = async (obj: { contractId: string; couponId: string }): Promise<void> => {
    try {
      await api.coupon<DeleteCouponReq, void>({
        process: 'delete',
        contractId: obj.contractId,
        couponId: obj.couponId,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    coupons: couponListItems,
    couponList: couponList,
    fetchCoupons: fetchCoupons,
    addCoupon: addCoupon,
    updateCoupon: updateCoupon,
    updateUsageCount: updateUsageCount,
    deleteCoupon: deleteCoupon,
  };
});
