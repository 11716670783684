<template>
  <v-row dense>
    <v-col cols="12">
      <v-select
        v-model="coupon.id"
        :items="couponItems"
        item-title="title"
        item-value="value"
        hint="クーポンを選択してください"
        density="compact"
        variant="outlined"
        :clearable="!required"
        persistent-hint
        :loading="loading"
        :rules="rules.require"
        :disabled="disabled"
        @update:model-value="updateCouponItem"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="8" v-show="isShowExpirationDays">
      <v-text-field
        v-model.number="coupon.expirationDays"
        label="有効期限日数"
        prefix="配布から"
        suffix="日間有効"
        density="compact"
        variant="outlined"
        hide-details="auto"
        :rules="rules.moreThanZero"
        :disabled="disabled"
        @update:model-value="updateCouponItem"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="8">
      <v-checkbox
        v-model="coupon.useType"
        :true-value="useTypeMultiTime"
        :false-value="useTypeOneTime"
        :disabled="!existEndDate || disabled"
        label="利用回数の制限なし"
        hide-details="auto"
        @update:model-value="updateCouponItem"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Options } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { Formattable } from '@/common/mixin/formattable';
import { useAppStore } from '@/stores/app';
import { useCouponStore } from '@/stores/coupon';
import { couponDataSource, useType } from '@/common/appCode';
import { CouponListItem } from '@/store/models/db/common-models';
import * as Utils from '@/common/utils';

export interface CouponParams {
  id: string;
  name: string;
  useType: useType;
  expirationDays: number | null;
  dataSource: couponDataSource;
}

@Options({
  emits: ['updateCouponItem'],
})
export default class SelectCoupon extends Formattable {
  /** Props **/
  @Prop({ default: true }) required!: boolean;
  @Prop({ default: '' }) couponId!: string;
  @Prop({ default: useType.oneTime }) useType!: useType;
  @Prop({ default: null }) expirationDays!: number | null;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isShowExpirationDays!: boolean;

  /** Data */
  loading = false;

  appStore = useAppStore();
  couponStore = useCouponStore();

  existEndDate = true;
  coupon: CouponParams = {
    id: '',
    name: '',
    useType: useType.oneTime,
    expirationDays: null,
    dataSource: couponDataSource.internal,
  };

  /** Computed **/
  get rules(): { [key: string]: Array<(key) => boolean | string> } {
    return {
      require: [(value: string): boolean | string => !this.required || !!value || 'クーポンを選択してください。'],
      moreThanZero: [
        (value: string): boolean | string =>
          value === null || value === '' || Utils.isInteger(value) || '整数で入力してください',
        (value: string): boolean | string =>
          value === null || value === '' || parseInt(value) >= 1 || '1より大きい数値を入力してください',
      ],
    };
  }

  get couponItems(): Array<{ title: string; value: string }> {
    return this.coupons.map((val) => ({ title: val.couponName, value: val.couponId }));
  }

  get coupons(): CouponListItem[] {
    return this.couponStore.couponList.filter(
      (val) => !val.endDate || Utils.isDateSameOrAfter(val.endDate, new Date())
    );
  }

  get useTypeOneTime(): useType {
    return useType.oneTime;
  }

  get useTypeMultiTime(): useType {
    return useType.multiTime;
  }

  /** Methods */
  @Emit('updateCouponItem')
  updateCouponItem(): CouponParams {
    const coupon = this.coupons.find((val) => val.couponId === this.coupon.id);
    this.existEndDate = coupon?.endDate ? true : false;
    this.coupon = {
      id: this.coupon.id || '', // clearableでクリアするとundefinedになるため,
      name: coupon?.couponName || '',
      useType: this.existEndDate ? this.coupon.useType : useType.oneTime,
      expirationDays:
        this.coupon.expirationDays === 0 || this.coupon.expirationDays ? this.coupon.expirationDays : null,
      dataSource: coupon?.dataSource || couponDataSource.internal,
    };
    return this.coupon;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  /** Life Cycle */
  async mounted(): Promise<void> {
    this.startLoading();
    await this.couponStore.fetchCoupons({ contractId: this.contractId }).finally(() => {
      this.stopLoading();
    });

    this.coupon.id = this.couponId;
    this.coupon.useType = this.useType;
    this.coupon.expirationDays = this.expirationDays;

    const coupon = this.coupons.find((val) => val.couponId === this.coupon.id);
    this.existEndDate = coupon?.endDate ? true : false;
  }
}
</script>
