import { RStaff } from '@/store/models/db/r-staff';

/** アプリケーションで用いるモデル **/
export type StaffModel = RStaff;

export class Staff {
  constructor(public params: StaffModel) {}

  /** Getter */
  get id(): string {
    return this.params.id;
  }
}
