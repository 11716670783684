import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import * as Utils from '@/common/utils';
import { DataConflict, DocumentationDoesNotExist, MaximumNumberOfRegisteredError, StoreError } from '@/common/error';
import { api } from '@/firebase/api';
import { ImportMenuCsvDataReq, ImportMenuCsvDataRes, MenuCsvData } from '@/firebase/dto';
import { Unsubscribe, deleteField, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { docs } from '@/firebase/dao';
import { categoryType, salesType, visibilitySettingType } from '@/common/appCode';
import { Multilingual } from '@/store/models/db/common-models';
import { UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes } from '@/firebase/dto/smaregi';
import { GetMenuCsvDataReq, GetMenuCsvDataRes } from '@/firebase/dto/get-csv';
import { MenuCategory } from '@/store/models/menu-category';
import {
  AliasMenu,
  OrderTimeframe,
  PreOrderDeadline,
  RMenuCategories,
  SpecifyDate,
  SubCategories,
} from '@/store/models/db/r-menu-categories';
import { Menu } from '@/store/models/menu';
import { useUsersStore } from '@/stores/users';
import { useMenusStore } from '@/stores/menus';

export const useMenuCategoriesStore = defineStore('menuCategories', () => {
  const userStore = useUsersStore();
  const menusStore = useMenusStore();

  // stateはref変数
  const menuCategories = ref({}) as Ref<{ [key: string]: MenuCategory }>;
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const getMenuCategories = computed(() => menuCategories.value);
  const getMenuCategoryArray = computed(() =>
    Object.keys(menuCategories.value)
      .map((key) => menuCategories.value[key])
      .sort((m1, m2) => {
        if (m1.params.sort_key > m2.params.sort_key) return 1;
        if (m1.params.sort_key < m2.params.sort_key) return -1;
        return 0;
      })
  );

  // actionはmutationと統合して、関数

  const startMenuCategoriesSubscribe = (obj: { contractId: string }) => {
    console.log('startSubscribe r_menu_categories');
    // メニューカテゴリの情報をリッスン
    const unsubscriber = onSnapshot(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), (doc) => {
      if (doc.exists()) {
        const rMenuCategories = doc.data() as { [key: string]: RMenuCategories };
        menuCategories.value = Object.keys(rMenuCategories).reduce(
          (obj, key) => ({
            ...obj,
            [key]: new MenuCategory(rMenuCategories[key], { key: key }),
          }),
          {}
        );
      }
    });
    dbUnsubscriber.value = unsubscriber;
  };

  const stopMenuCategoriesSubscribe = () => {
    console.log('stopSubscribe r_menu_categories');
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
  };

  /**
   * メニューカテゴリの更新
   *
   * @param obj
   */
  const setMenuCategories = (obj: { menuCategories: { [key: string]: RMenuCategories } }) => {
    menuCategories.value = Object.keys(obj.menuCategories).reduce(
      (res, key) => ({
        ...res,
        [key]: new MenuCategory(obj.menuCategories[key], { key: key }),
      }),
      {}
    );
  };

  /**
   * カテゴリーを取得
   *
   * @param obj
   */
  const fetchMenuCategories = async (obj: { contractId: string }) => {
    try {
      const doc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));
      const rMenuCategories = (doc.exists() ? doc.data() : {}) as { [key: string]: RMenuCategories };
      menuCategories.value = Object.keys(rMenuCategories).reduce(
        (obj, key) => ({
          ...obj,
          [key]: new MenuCategory(rMenuCategories[key], { key: key }),
        }),
        {}
      );
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * カテゴリの追加
   *
   * @param rootState
   * @param obj
   */
  const addCategory = async (obj: {
    contractId: string;
    category: {
      name: string;
      multilingualNames: Multilingual;
      categoryType: string[];
      unshownCategoryType: string[];
      plasticBag: boolean;
      isTakeout: boolean;
      type: categoryType;
      alias: boolean;
      visibility: visibilitySettingType;
      specifyDate?: SpecifyDate | null;
      preOrderDeadline?: PreOrderDeadline | null;
      orderTimeframe?: OrderTimeframe | null;
      hiddenSalesType?: salesType[];
    };
    smaregi: boolean;
  }) => {
    try {
      // カテゴリ登録数が上限に達している場合エラーをthrow
      if (Object.keys(menuCategories.value).length >= MenuCategory.MAX_NUMBER_OF_CATEGORY_REGISTERED) {
        throw new MaximumNumberOfRegisteredError('通常カテゴリ', MenuCategory.MAX_NUMBER_OF_CATEGORY_REGISTERED);
      }

      // カテゴリーIDの割当
      const newCategoryId = obj.smaregi
        ? // スマレジ部門登録（IDを取得）
          await api
            .smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
              process: 'update_smaregi_category',
              method: 'add',
              contractId: obj.contractId,
              storeId: userStore.getStoreId,
              categoryId: '',
              category: { name: obj.category.name },
            })
            .then((r) => r.categoryId)
        : Utils.makeNewCategoryId();

      const sortKey =
        getMenuCategoryArray.value.length === 0
          ? 0
          : Math.max(...getMenuCategoryArray.value.map((val) => val.params.sort_key)) + 1;

      const params: { [key: string]: RMenuCategories } = {
        [newCategoryId]: {
          type: obj.category.type,
          alias: obj.category.alias,
          category_type: obj.category.categoryType,
          image_url: '',
          name: obj.category.name,
          multilingualNames: obj.category.multilingualNames,
          sort_key: sortKey,
          sub_categories: {},
          unshownCategoryType: obj.category.unshownCategoryType,
          plasticBag: obj.category.plasticBag,
          visibility: obj.category.visibility,
          isTakeout: obj.category.isTakeout,
          specifyDate: obj.category.specifyDate,
          preOrderDeadline: obj.category.preOrderDeadline,
          orderTimeframe: obj.category.orderTimeframe,
          hiddenSalesType: obj.category.hiddenSalesType,
        },
      };

      await setDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), params, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * カテゴリの編集
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const updateCategory = async (obj: {
    contractId: string;
    category: {
      categoryNo: string;
      name: string;
      multilingualNames: Multilingual;
      categoryType: string[];
      unshownCategoryType: string[];
      visibility: visibilitySettingType;
      isTakeout: boolean;
      type: categoryType;
      alias: boolean;
      plasticBag: boolean;
      specifyDate?: SpecifyDate | null;
      preOrderDeadline?: PreOrderDeadline | null;
      orderTimeframe?: OrderTimeframe | null;
      hiddenSalesType?: salesType[];
    };
    smaregi: boolean;
  }) => {
    try {
      const param: { [key: string]: RMenuCategories } = {
        [obj.category.categoryNo]: {
          type: obj.category.type,
          alias: obj.category.alias,
          name: obj.category.name,
          multilingualNames: obj.category.multilingualNames,
          category_type: obj.category.categoryType,
          image_url: menuCategories.value[obj.category.categoryNo].params.image_url,
          sort_key: menuCategories.value[obj.category.categoryNo].params.sort_key,
          unshownCategoryType: obj.category.unshownCategoryType,
          sub_categories: menuCategories.value[obj.category.categoryNo].params.sub_categories,
          visibility: obj.category.visibility,
          isTakeout: obj.category.isTakeout,
          plasticBag: obj.category.plasticBag,
          specifyDate: obj.category.specifyDate,
          preOrderDeadline: obj.category.preOrderDeadline,
          orderTimeframe: obj.category.orderTimeframe,
          hiddenSalesType: obj.category.hiddenSalesType,
        },
      };

      await setDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), param, { merge: true });

      if (obj.smaregi) {
        await api.smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
          process: 'update_smaregi_category',
          method: 'update',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          categoryId: obj.category.categoryNo,
          category: { name: obj.category.name },
        });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * カテゴリの削除
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const deleteCategory = async (obj: { contractId: string; categoryNo: string; smaregi: boolean }) => {
    try {
      const menus: Menu[] = menusStore.getMenuArray;

      const childMenus = menus.filter((val) => val.params.category_no === obj.categoryNo);

      // 関連商品なし or 削除確認
      if (
        childMenus.length === 0 ||
        confirm('このカテゴリーに関連する商品が存在します。関連商品は全て削除されます。本当に削除しますか？')
      ) {
        const menuCategoryDoc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));

        const menuCategories = menuCategoryDoc.data() as { [key: string]: RMenuCategories };

        // カテゴリの削除
        delete menuCategories[obj.categoryNo];

        // メニューを削除
        await menusStore.deleteMenuBulk({ contractId: obj.contractId, menus: childMenus });

        // 更新
        await setDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), menuCategories);

        // スマレジ部門削除
        if (obj.smaregi) {
          await api.smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
            process: 'update_smaregi_category',
            method: 'delete',
            contractId: obj.contractId,
            storeId: userStore.getStoreId,
            categoryId: obj.categoryNo,
            category: { name: '' },
          });
        }
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * カテゴリの並び替え
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const sortCategory = async (obj: { contractId: string; sortedArray: MenuCategory[] }) => {
    try {
      // ドキュメントの存在確認
      const menuCategoryDoc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));

      if (!menuCategoryDoc.exists()) {
        throw new DocumentationDoesNotExist();
      }

      // const menuCategory = menuCategoryDoc.data() as { [key: string]: RMenuCategories };

      const fieldName: keyof RMenuCategories = 'sort_key';

      const params = obj.sortedArray.reduce(
        (res, menuCategory, index) => ({ ...res, [menuCategory.params.key]: { [fieldName]: index } }),
        {}
      );

      await setDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), params, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * サブカテゴリの追加
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const addSubCategory = async (obj: {
    contractId: string;
    subCategory: {
      categoryNo: string;
      name: string;
      multilingualNames: Multilingual;
      description: string;
      multilingualDescription: Multilingual;
    };
    smaregi: boolean;
  }) => {
    try {
      const menuCategoryDoc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));

      if (!menuCategoryDoc.exists()) {
        throw new DocumentationDoesNotExist();
      }

      const menuCategory = menuCategoryDoc.data()[obj.subCategory.categoryNo] as RMenuCategories;

      if (!menuCategory) {
        throw new DataConflict();
      }

      const subCategories = menuCategory.sub_categories;

      // カテゴリーIDの割当
      const newSubCategoryId = obj.smaregi
        ? // スマレジ部門登録（IDを取得）
          await api
            .smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
              process: 'update_smaregi_category',
              method: 'add',
              contractId: obj.contractId,
              storeId: userStore.getStoreId,
              categoryId: '',
              parentCategoryId: obj.subCategory.categoryNo, // 親部門ID
              category: { name: obj.subCategory.name },
            })
            .then((r) => r.categoryId)
        : Utils.makeNewSubCategoryId();

      const sortKey =
        Object.keys(subCategories).length === 0
          ? 0
          : Math.max(...Object.keys(subCategories).map((key) => subCategories[key].sort_key)) + 1;

      const fieldName: keyof RMenuCategories = 'sub_categories';

      const params: SubCategories = {
        name: obj.subCategory.name,
        multilingualNames: obj.subCategory.multilingualNames,
        description: obj.subCategory.description,
        multilingualDescription: obj.subCategory.multilingualDescription,
        sort_key: sortKey,
      };

      await updateDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), {
        [`${obj.subCategory.categoryNo}.${fieldName}.${newSubCategoryId}`]: params,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * サブカテゴリの編集
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const updateSubCategory = async (obj: {
    contractId: string;
    subCategory: {
      categoryNo: string;
      subCategoryNo: string;
      name: string;
      multilingualNames: Multilingual;
      description: string;
      multilingualDescription: Multilingual;
      aliasMenus?: AliasMenu[];
    };
    smaregi: boolean;
  }) => {
    try {
      const fieldName: keyof RMenuCategories = 'sub_categories';
      const nameName: keyof SubCategories = 'name';
      const descriptionName: keyof SubCategories = 'description';
      const multilingualNamesName: keyof SubCategories = 'multilingualNames';
      const multilingualDescriptionName: keyof SubCategories = 'multilingualDescription';
      const aliasMenusName: keyof SubCategories = 'aliasMenus';

      await updateDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), {
        [`${obj.subCategory.categoryNo}.${fieldName}.${obj.subCategory.subCategoryNo}.${nameName}`]:
          obj.subCategory.name,
        [`${obj.subCategory.categoryNo}.${fieldName}.${obj.subCategory.subCategoryNo}.${multilingualNamesName}`]:
          obj.subCategory.multilingualNames,
        [`${obj.subCategory.categoryNo}.${fieldName}.${obj.subCategory.subCategoryNo}.${descriptionName}`]:
          obj.subCategory.description,
        [`${obj.subCategory.categoryNo}.${fieldName}.${obj.subCategory.subCategoryNo}.${multilingualDescriptionName}`]:
          obj.subCategory.multilingualDescription,
        ...(obj.subCategory.aliasMenus
          ? {
              [`${obj.subCategory.categoryNo}.${fieldName}.${obj.subCategory.subCategoryNo}.${aliasMenusName}`]:
                obj.subCategory.aliasMenus,
            }
          : {}),
      });

      // 親カテゴリーと子カテゴリーのIDが一致する場合はスマレジ側は更新しない（親部門から自動生成されたサブカテゴリーのため）
      if (obj.smaregi && obj.subCategory.subCategoryNo !== obj.subCategory.categoryNo) {
        await api.smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
          process: 'update_smaregi_category',
          method: 'update',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          categoryId: obj.subCategory.subCategoryNo,
          parentCategoryId: obj.subCategory.categoryNo,
          category: { name: obj.subCategory.name },
        });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * サブカテゴリの削除
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const deleteSubCategory = async (obj: {
    contractId: string;
    categoryNo: string;
    subCategoryNo: string;
    smaregi: boolean;
  }) => {
    try {
      // メニューの存在確認
      const menus: Menu[] = menusStore.getMenuArray;

      const childMenus = menus.filter(
        (val) => val.params.category_no === obj.categoryNo && val.params.sub_category_no === obj.subCategoryNo
      );

      // 関連商品なし or 削除確認
      if (
        childMenus.length === 0 ||
        confirm('このサブカテゴリーに関連する商品が存在します。関連商品は全て削除されます。本当に削除しますか？')
      ) {
        // ドキュメントの存在確認
        const menuCategoryDoc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));

        if (!menuCategoryDoc.exists()) {
          throw new DocumentationDoesNotExist();
        }

        const menuCategories = menuCategoryDoc.data() as { [key: string]: RMenuCategories };

        if (!menuCategories[obj.categoryNo]) {
          throw new DataConflict();
        }

        if (
          obj.subCategoryNo === obj.categoryNo &&
          !confirm(
            'このサブカテゴリーはスマレジの親部門から自動生成されたサブカテゴリーです。このサブカテゴリーを削除すると生成元のスマレジの親部門および関連する商品も全て削除されます。本当に実行しますか？'
          )
        ) {
          return;
        }

        const fieldName: keyof RMenuCategories = 'sub_categories';

        // メニューを削除
        await menusStore.deleteMenuBulk({ contractId: obj.contractId, menus: childMenus });

        await updateDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId), {
          [`${obj.categoryNo}.${fieldName}.${obj.subCategoryNo}`]: deleteField(),
        });

        // スマレジ部門
        if (obj.smaregi) {
          await api.smaregi<UpdateSmaregiCategoryReq, UpdateSmaregiCategoryRes>({
            process: 'update_smaregi_category',
            method: 'delete',
            contractId: obj.contractId,
            storeId: userStore.getStoreId,
            categoryId: obj.subCategoryNo,
            category: { name: '' },
          });
        }
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * サブカテゴリの並び替え
   *
   * @param state
   * @param rootState
   * @param obj
   */
  const sortSubCategory = async (obj: {
    contractId: string;
    categoryNo: string;
    sortedArray: Array<{ key: string; name: string; sorted_key: number }>;
  }) => {
    try {
      // ドキュメントの存在確認
      const menuCategoryDoc = await getDoc(docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId));
      if (!menuCategoryDoc.exists()) {
        throw new DocumentationDoesNotExist();
      }

      const menuCategory = menuCategoryDoc.data() as { [key: string]: RMenuCategories };

      // キーの配列の長さとカテゴリ数が一致しない場合は失敗
      const menuSubCategoryKeys = Object.keys(menuCategory[obj.categoryNo].sub_categories);
      if (menuSubCategoryKeys.length === obj.sortedArray.length) {
        // カテゴリのキーに対して順に番号を振っていく
        await updateDoc(
          docs.menuCategoriesDoc(obj.contractId, userStore.getStoreId),
          obj.sortedArray.reduce(
            (res, menuSubCategory, index) => ({
              ...res,
              [`${obj.categoryNo}.sub_categories.${menuSubCategory.key}.sort_key`]: index,
            }),
            {}
          )
        );
      } else {
        throw new DataConflict();
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューCSV出力データの取得
   *
   * @param obj
   */
  const getMenuCsvData = async (obj: { contractId: string }): Promise<GetMenuCsvDataRes> => {
    try {
      return await api.getCsv<GetMenuCsvDataReq, GetMenuCsvDataRes>({
        process: 'menu',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューCSVデータのインポート取得
   *
   * @param obj
   */
  const importMenuCsvData = async (obj: {
    contractId: string;
    csvData: MenuCsvData[];
  }): Promise<ImportMenuCsvDataRes> => {
    try {
      return await api.updateMenu<ImportMenuCsvDataReq, ImportMenuCsvDataRes>({
        process: 'import',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        csvData: obj.csvData,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    getMenuCategories,
    getMenuCategoryArray,
    startMenuCategoriesSubscribe,
    stopMenuCategoriesSubscribe,
    fetchMenuCategories,
    setMenuCategories,
    addCategory,
    updateCategory,
    deleteCategory,
    sortCategory,
    addSubCategory,
    updateSubCategory,
    deleteSubCategory,
    sortSubCategory,
    getMenuCsvData,
    importMenuCsvData,
  };
});
