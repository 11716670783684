<template>
  <div>
    <!-- ログイン済みの場合にメニューを表示 -->
    <v-navigation-drawer v-model="drawer" class="bg-grey-darken-2">
      <v-list dark>
        <v-list-item @click="onPushStore">
          <template v-slot:prepend>
            <v-avatar>
              <v-img v-if="isEatInStore" src="img/anytime_icon.jpg"></v-img>
              <v-img v-else-if="isTakeoutStore" src="img/anywhere_icon.jpg"></v-img>
              <v-img v-else-if="isEcStore" src="img/ec_icon.jpg"></v-img>
              <v-img v-else-if="isWaitingStore" src="img/waiting_icon.png"></v-img>
            </v-avatar>
          </template>
          <v-list-item-title class="text-body-1"> {{ companyName }} </v-list-item-title>
          <v-list-item-subtitle class="text-caption note" dense>{{
            is2ndGenData && storeId ? storeName : time
          }}</v-list-item-subtitle>
          <template v-if="isStoreSwitchable" v-slot:append>
            <v-btn icon="keyboard_arrow_right" size="small" variant="text"></v-btn>
          </template>
        </v-list-item>
        <v-list-item
          v-if="(isEatInStore || isTakeoutStore) && !isHeadquarterOf2ndGen"
          density="compact"
          @click.stop="onPushStaff"
        >
          <template v-slot:prepend>
            <v-icon size="large">person</v-icon>
          </template>

          <v-list-item-subtitle class="text-caption">担当者</v-list-item-subtitle>
          <v-list-item-title class="text-subtitle-1">{{ currentStaffName }}</v-list-item-title>
          <template v-slot:append>
            <v-icon>keyboard_arrow_right</v-icon>
          </template>
        </v-list-item>
        <v-divider color="grey"></v-divider>
        <template v-for="item in individualPage" :key="item.name">
          <v-list-item @click="onPushItem(item)" link>
            <template v-slot:prepend>
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
          <v-divider color="grey"></v-divider>
        </template>
        <template v-for="item in groupes" :key="item.name">
          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <template v-slot:prepend>
                  <v-icon>{{ item.icon }}</v-icon>
                </template>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item v-for="(page, i) in item.pages" :key="i" @click="onPushItem(page)" link>
              <v-list-item-title>{{ page.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-divider color="grey"></v-divider>
        </template>
        <v-divider color="grey"></v-divider>
        <v-list-item @click="onPushHelpDocument">
          <template v-slot:prepend>
            <v-icon>mdi:mdi-help-circle</v-icon>
          </template>
          <v-list-item-title>ご利用ガイド</v-list-item-title>
        </v-list-item>
        <v-divider color="grey"></v-divider>
        <v-list-item @click="onPushLogout">
          <template v-slot:prepend>
            <v-icon>exit_to_app</v-icon>
          </template>

          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
        <v-divider color="grey"></v-divider>
      </v-list>
      <template v-slot:append>
        <div class="pa-3 text-caption">Ver. {{ appVersion }}</div>
      </template>
    </v-navigation-drawer>
    <v-app-bar color="primary" elevation="1" density="compact">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-center">{{ pageTitle }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip v-if="!isProduction" theme="light" size="small" label variant="flat">
        <span v-if="isStaging">STAGING</span>
        <span v-else-if="isLocal">LOCAL</span>
      </v-chip>
      <v-btn icon @click="onPushMessage()" v-if="!isMessageDisabled()">
        <v-badge color="accent" v-if="messageCount >= 1">
          <template v-slot:badge>
            <span>{{ messageCount }}</span>
          </template>
          <v-icon>mdi:mdi-message-text</v-icon>
        </v-badge>
        <v-icon v-else>mdi:mdi-message-text</v-icon>
      </v-btn>
      <v-btn icon @click="onPushRefresh()">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn v-if="!isHeadquarter" icon @click="onPushSetting()">
        <v-icon>settings</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Dialogs -->
    <message-dialog ref="messageDialog" :messages="messages" @error="errorPropagation" />
    <select-staff-dialog ref="selectStaffDialog" @changeStaff="changeStaff" @error="errorPropagation" />
    <v-snackbar v-model="showSnackBar" color="message" location="top" vertical :timeout="30000" class="note">
      <div class="text-black text-h6">{{ messageText }}</div>
      <br />
      <div v-if="messageFrom" class="text-black text-h6">From : {{ messageFrom }}</div>
      <template v-slot:actions>
        <v-btn theme="dark" variant="text" class="text-black" v-bind="$attrs" @click="showSnackBar = false"
          >閉じる</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="showUpdateSnackBar" location="bottom" vertical :timeout="-1">
      アプリの新しいバージョンがあります。更新を押してアプリを最新バージョンに更新してください。
      <template v-slot:actions>
        <v-btn theme="dark" variant="text" class="text-black" color="red" v-bind="$attrs" @click="onPushUpdate()"
          >更新</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Prop, Emit, Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import * as Pages from '@/router/index';
import * as Utils from '../../common/utils';
import { CommonSettings } from '@/common/common-settings';
import MessageDialog from '@/components/molecules/MessageDialog.vue';
import SelectStaffDialog from '@/components/molecules/SelectStaffDialog.vue';
import { useMessagesStore } from '@/stores/messages';
import { useStaffsStore } from '@/stores/staffs';
import { MessageTimeLine } from '../../store/models/common-sys-models';
import { App } from '@/store/models/app';
import { Howl } from 'howler';
import ToolTip from '@/components/molecules/ToolTip.vue';
import { Permission, selectStoreMode } from '@/common/appCode';
import { Groupe, RESTAURAN_INFO } from '@/router/index';
import { Formattable } from '@/common/mixin/formattable';
import { Staff } from '@/store/models/staff';

@Options({
  components: {
    'message-dialog': MessageDialog,
    'select-staff-dialog': SelectStaffDialog,
    'tool-tip': ToolTip,
  },
  emits: ['onPushItem', 'onPushLogout', 'error'],
})
export default class MenuBar extends Formattable {
  // Data
  messagesStore = useMessagesStore();
  staffsStore = useStaffsStore();

  drawer = false;

  showSnackBar = false;
  staff = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.SELECT_STAFF);
  messageText = '';
  messageFrom = '';
  messageCount = 0;
  time = '';

  sounds: { [key: string]: Howl } = {
    [CommonSettings.FILE_NAME.MESSAGE_SOUND_1]: new Howl({
      src: [CommonSettings.FILE_NAME.MESSAGE_SOUND_1],
      volume: 1,
      sprite: {
        play1: [0, 2000], // 開始から2秒間を再生
      },
    }),
    [CommonSettings.FILE_NAME.MESSAGE_SOUND_2]: new Howl({
      src: [CommonSettings.FILE_NAME.MESSAGE_SOUND_2],
      volume: 1,
      sprite: {
        play1: [0, 4000], // 開始から4秒間を再生
      },
    }),
  };

  /** Refs **/
  @Ref('messageDialog') messageDialog!: MessageDialog;
  @Ref('selectStaffDialog') selectStaffDialog!: SelectStaffDialog;

  // Props
  @Prop({ default: '' }) appVersion!: string; // アプリバージョン
  @Prop({ default: '' }) pageTitle!: string; // 画面名
  @Prop({ default: [] }) pageOrder!: Pages.PageInfo[]; // メニューバーのリスト

  get messages(): MessageTimeLine[] {
    return this.messagesStore.getMessageTimeLines;
  }

  get companyName(): string {
    return this.userStore.getAccountSetting?.params?.companyName ?? '';
  }

  get staffsList(): Staff[] {
    return this.staffsStore.displayedStaffsList;
  }

  get selectStaffs(): Array<{ text: string; value: string }> {
    return this.staffsList.map((val) => ({ text: val.params.name, value: val.id }));
  }

  get app(): App {
    return this.userStore.getApp;
  }

  get showUpdateSnackBar(): boolean {
    return this.app.isCurrentVersion === false && this.userStore.getLogin;
  }

  // 表示可能なページ
  get showMenus(): Pages.PageInfo[] {
    return [
      ...(this.isEatInStore || this.isTakeoutStore ? Pages.eatinTakeoutPageOrder : []),
      ...(this.isEcStore ? Pages.ecPageOrder : []),
      ...(this.isWaitingStore ? Pages.waitingPageOrder : []),
      ...(this.isMembershipStore ? Pages.membershipPageOrder : []),
      ...Pages.commonPageOrder,
    ].filter(
      (val) =>
        (this.isAdminUser ||
          (this.isNormalUser && val.permission !== Permission.admin) ||
          val.permission === Permission.member) &&
        (!val.is2ndGenMenu || (val.is2ndGenMenu && this.accountSetting.is2ndGenData)) &&
        (!val.headquartersOnly || (val.headquartersOnly && this.isHeadquarter)) &&
        (!val.hiddenHeadquarters || !this.isHeadquarterOf2ndGen)
    );
  }

  // 単一ページ
  get individualPage(): Pages.PageInfo[] {
    const showMenusRoutes = this.showMenus.map((val) => val.route);

    // 表示可能なページを抽出
    return this.pageOrder.filter((val) => showMenusRoutes.includes(val.route));
  }

  // ページグループ
  get groupes(): Groupe[] {
    return (
      Pages.groupes
        .map((val) => ({
          ...val,
          // 表示可能なページを抽出
          pages: val.pages.filter((page) => this.showMenus.map((val) => val.route).includes(page.route)),
        }))
        // ページグループにページが存在するもののみ抽出
        .filter((val) => val.pages.length > 0)
    );
  }

  get isStoreSwitchable(): boolean {
    return this.is2ndGenData && this.isHeadquarterLogin;
  }

  @Watch('messages')
  notifiesForMessage(newVal: MessageTimeLine[], oldVal: MessageTimeLine[]): void {
    const newReceive = newVal
      .filter((val) => val.type === 'receive' || val.type === 'notification')
      .sort((a, b) => (a.sendTime < b.sendTime ? 1 : -1));
    const oldReceive = oldVal
      .filter((val) => val.type === 'receive' || val.type === 'notification')
      .sort((a, b) => (a.sendTime < b.sendTime ? 1 : -1));

    if (
      !this.userStore.getLogin ||
      !this.userStore.getInitAccountTime ||
      newReceive.length === 0 ||
      oldReceive.length >= newReceive.length ||
      this.userStore.getInitAccountTime.getTime() > newReceive[0].sendTime.getTime()
    ) {
      return;
    }
    this.showSnackBar = true;
    this.messageText = newReceive[0].message;
    this.messageFrom = `${newReceive[0].lineUserName}${newReceive[0].tableNo ? `（${newReceive[0].tableNo}）` : ''}`;
    this.messageCount++;

    const soundType: string | null = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.MESSAGE_NOTIFICATION_SOUND);

    if (soundType !== CommonSettings.FILE_NAME.MESSAGE_SOUND_NONE) {
      this.playSound(this.sounds[soundType || CommonSettings.FILE_NAME.MESSAGE_SOUND_1]);
    }
  }

  playSound(sound: Howl): void {
    sound.play('play1');
  }
  /** スタッフ **/
  onPushStaff(): void {
    this.selectStaffDialog.open({ staffId: this.staff ?? '', staffList: this.staffsList });
  }

  /** メッセージ **/
  onPushMessage(): void {
    this.messageCount = 0;
    this.messageDialog.open();
  }

  /** 店舗 */
  onPushStore(): void {
    if (!this.isStoreSwitchable) {
      return;
    }
    this.$router.push({ path: '/select-store', query: { mode: selectStoreMode.change } });
  }

  /** リロード */
  onPushRefresh(): void {
    window.location.reload();
  }

  onPushUpdate(): void {
    window.location.href = '/home';
  }

  onPushHelpDocument(): void {
    window.open('https://choose-monster.notion.site/3680fa7559f64eab934aad1f6536639a', '_blank');
  }

  /** 設定 */
  @Emit('onPushItem')
  onPushSetting(): Pages.PageInfo {
    return RESTAURAN_INFO;
  }

  formateDate(date: Date): string {
    return Utils.dateTimeFormatBySlash(date);
  }

  isMessageDisabled(): boolean {
    return !this.settings.params?.messaging_service;
  }

  changeStaff(value: string): void {
    this.staff = value;
    localStorage.setItem(CommonSettings.WEB_STORAGE_KEY.SELECT_STAFF, value);
    this.userStore.setStaffId({ staffId: value });
    // TODO:Storeにも保存する
  }

  // Methods
  @Emit('onPushItem')
  onPushItem(item: Pages.PageInfo): Pages.PageInfo {
    return item;
  }

  @Emit('onPushLogout')
  onPushLogout(): void {}

  @Emit('error')
  errorPropagation(error: Error): Error {
    return error;
  }

  mounted(): void {
    setInterval(() => {
      this.time = Utils.dateTimeFormatJapanese(new Date());
    }, 1000);
  }
}
</script>

<style scoped>
.note {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
