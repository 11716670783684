import { collection, CollectionReference, doc, DocumentData, DocumentReference } from 'firebase/firestore';
import { ref, StorageReference } from 'firebase/storage';
import { db, storage } from './index';

export const refName = {
  app: 'cApp',
  guest: 'r_guests',
  transactions: 'r_transactions',
  accountSetting: 'r_account_setting',
  setting: 'r_settings',
  pushNotification: 'r_push_notification',
  categoryTypes: 'r_category_types',
  menuCategories: 'r_menu_categories',
  menuUpdate: 'r_menu_update',
  message: 'r_message',
  waitings: 'r_waitings',
  questionnaire: 'r_questionnaire',
  messageAutomation: 'r_message_automation',
  membersRank: 'r_members_rank',
  staff: 'r_staff',
  rSeq: 'r_seq',
  media: 'r_media',
  deliveryFeesByPostalCode: 'm_delivery_fees_by_postal_code',

  transactionList: 'transactionList',
};

export const docs = {
  appRef: (id: string): DocumentReference<DocumentData> => doc(db, refName.app, id),
  accountSettingDoc: (id: string): DocumentReference<DocumentData> => doc(db, refName.accountSetting, id),
  guestDoc: (id: string): DocumentReference<DocumentData> => doc(db, refName.guest, id),
  pushNotificationDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.pushNotification, id, `${refName.pushNotification}_stores`, storeId)
      : doc(db, refName.pushNotification, id),
  settingDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.setting, id, `${refName.setting}_stores`, storeId) : doc(db, refName.setting, id),
  categoryTypesDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.categoryTypes, id, `${refName.categoryTypes}_stores`, storeId)
      : doc(db, refName.categoryTypes, id),
  menuCategoriesDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.menuCategories, id, `${refName.menuCategories}_stores`, storeId)
      : doc(db, refName.menuCategories, id),
  menuUpdateDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.menuUpdate, id, `${refName.menuUpdate}_stores`, storeId)
      : doc(db, refName.menuUpdate, id),
  messageDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.message, id, `${refName.message}_stores`, storeId) : doc(db, refName.message, id),
  waitingsDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.waitings, id, `${refName.waitings}_stores`, storeId) : doc(db, refName.waitings, id),
  membersRankDoc: (id: string): DocumentReference<DocumentData> => doc(db, refName.membersRank, id), // 全店舗共通
  staffDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.staff, id, `${refName.staff}_stores`, storeId) : doc(db, refName.staff, id),
  questionnaireDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.questionnaire, id, `${refName.questionnaire}_stores`, storeId)
      : doc(db, refName.questionnaire, id),
  messageAutomationDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.messageAutomation, id, `${refName.messageAutomation}_stores`, storeId)
      : doc(db, refName.messageAutomation, id),
  rSeqDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.rSeq, id, `${refName.rSeq}_stores`, storeId) : doc(db, refName.rSeq, id),
  mediaDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId ? doc(db, refName.media, id, `${refName.media}_stores`, storeId) : doc(db, refName.media, id),
  deliveryFeesByPostalCodeDoc: (id: string, storeId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(db, refName.deliveryFeesByPostalCode, id, `${refName.deliveryFeesByPostalCode}_stores`, storeId)
      : doc(db, refName.deliveryFeesByPostalCode, id),
  transactionListDoc: (docId: string, storeId: string, subDockId: string): DocumentReference<DocumentData> =>
    storeId
      ? doc(
          db,
          refName.transactions,
          docId,
          `${refName.transactions}_stores`,
          storeId,
          refName.transactionList,
          subDockId
        )
      : doc(db, refName.transactions, docId, refName.transactionList, subDockId),
};

export const subCollections = {
  transactionListCollection: (docId: string, storeId: string): CollectionReference<DocumentData> =>
    storeId
      ? collection(db, refName.transactions, docId, `${refName.transactions}_stores`, storeId, refName.transactionList)
      : collection(db, refName.transactions, docId, refName.transactionList),
};

export const storageRef = (path: string): StorageReference => ref(storage, path);
