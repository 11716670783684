import { RMembersRank } from '@/store/models/db/r-members-rank';

/** アプリケーションで用いるモデル **/
export type MembersRankModel = RMembersRank;

export class MembersRank {
  constructor(public params: MembersRankModel) {}

  /** Getter */
  get id(): string {
    return this.params.id;
  }

  get name(): string {
    return this.params.name;
  }

  get benefit(): string {
    return this.params.benefit;
  }

  get color1(): string {
    return this.params.color1;
  }

  get color2(): string {
    return this.params.color2;
  }

  get darkMode(): boolean {
    return this.params.darkMode;
  }

  get rankUpTerms(): number {
    return this.params.rankUpTerms;
  }

  get rankUpCount(): number {
    return this.params.rankUpCount;
  }

  get defaultRank(): boolean {
    return this.params.defaultRank;
  }

  get pointRate(): number {
    return this.params.pointRate;
  }

  get sortKey(): number {
    return this.params.sortKey;
  }
}
