<template>
  <v-snackbar v-model="display" color="error" :timeout="10000">
    <v-alert type="error">
      <div>{{ message }}</div>
    </v-alert>
    <template v-slot:actions>
      <v-btn icon theme="dark" v-bind="$attrs" @click="display = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ErrorDialog extends Vue {
  /** Data **/
  display = false;
  message = '';

  /** Methods **/
  open(obj: { message: string }): void {
    this.message = obj.message;
    this.display = true;
  }

  close(): void {
    this.display = false;
  }

  onPushEnterBtn(): void {
    this.close();
  }
}
</script>
