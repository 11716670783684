import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { Timestamp, deleteField, setDoc, updateDoc } from 'firebase/firestore';
import { StoreError } from '@/common/error';
import { EnvConfig } from '@/common/env-config';
import { CommonSettings } from '@/common/common-settings';
import { docs } from './dao';
import { firebaseApp } from '.';
import * as Utils from '@/common/utils';
import { RPushNotification } from '@/store/models/db/r-push-notification';

export const swjs = `firebase-messaging-sw${process.env.VUE_APP_MODE === 'production' ? '' : '-dev'}.js`;

// onMessage(messaging, (payload) => {
//   /*
//   const sound = new Howl({
//     src: './message1.mp3',
//     volume: 1,
//     sprite: {
//       play1: [0, 2000], // 開始から2秒間を再生
//     },
//   });
//   sound.play('play1');
//    */
//   // soundの再生もIt must be resumed (or created) after a user gesture on the page.
//   console.log('onMessage: ', payload);
// });

export const updateFcmToken = async (uid: string, storeId: string): Promise<void> => {
  if (!(await isSupported())) {
    // 通知非対応端末
    console.log('Notification not supported');
    return;
  }
  // serviceWorkerの2重登録を回避
  await unregisterSw();
  // serviceWorker登録
  const swRegistration = await navigator.serviceWorker
    .register(swjs, {
      updateViaCache: process.env.VUE_APP_MODE === 'production' ? 'imports' : 'none',
    })
    .catch((err) => {
      console.log('An error occurred while service worker registration. ', err);
    });

  if (!swRegistration) {
    return;
  }

  const now = new Date();

  const lastToken = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN);
  const lastTokenExpire = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN_EXPIRED);

  // トークンの期限が切れていない場合は何もしない
  if (lastTokenExpire && Utils.isBefore(now, new Date(lastTokenExpire ?? ''))) {
    return;
  }

  const messaging = getMessaging(firebaseApp);

  await getToken(messaging, {
    vapidKey: EnvConfig.firebase.FIREBASE_VAPID_KEY,
    serviceWorkerRegistration: swRegistration,
  })
    .then(async (currentToken) => {
      const expired = Utils.addMonths(now, 1);
      // 古いトークンはFirestoreから削除する
      if (lastToken) {
        await removeToken(lastToken, uid, storeId);
      }
      // localStorageに保存し、リロードなどでAPIから取得できない際に利用する
      localStorage.setItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN, currentToken);
      // expireを更新
      localStorage.setItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN_EXPIRED, expired.toISOString());
      // Firestoreに通知トークンを保存する（デバイスグループに追加）
      await addToken(currentToken, uid, storeId);
    })
    .catch(async (err) => {
      console.log('An error occurred while retrieving token. ', err);
      // 許可されていない場合のほかリロードなどでUI経由していないため取得できない場合もある（判定不能）
      if (!lastToken) {
        return;
      }

      // 期限切れの場合は再ログイン操作を促す
      if (Utils.isDateAfter(now, new Date(lastTokenExpire ?? ''))) {
        alert('通知を利用する場合は再度ログイン操作を行なってください');
        return;
      }

      // 保存済みのトークンが新鮮ならば利用する
      await addToken(lastToken, uid, storeId);
    });
};

export const addToken = async (token: string, uid: string, storeId: string): Promise<void> => {
  try {
    const updateParam: Partial<RPushNotification> = {
      fcmToken: {
        [token]: {
          token,
          expiredAt: Timestamp.fromDate(Utils.addMonths(new Date(), 1)),
          userAgent: navigator.userAgent,
        },
      },
    };
    // 更新
    await setDoc(docs.pushNotificationDoc(uid, storeId), updateParam, { merge: true });
  } catch (error) {
    throw new StoreError(error.name, error.message);
  }
};

export const removeToken = async (token: string, uid: string, storeId: string): Promise<void> => {
  try {
    localStorage.removeItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN);
    localStorage.removeItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN_EXPIRED);

    if (!uid) {
      return;
    }

    const fieldName: keyof RPushNotification = 'fcmToken';
    // 更新
    await updateDoc(docs.pushNotificationDoc(uid, storeId), {
      [`${fieldName}.${token}`]: deleteField(),
    });
  } catch (error) {
    throw new StoreError(error.name, error.message);
  }
};

export const unregisterSw = async (): Promise<void> => {
  const oldRegistrations = await navigator.serviceWorker.getRegistrations();

  if (!oldRegistrations) {
    return;
  }

  await Promise.all(
    oldRegistrations.map(async (registration) => {
      if ((registration.active?.scriptURL ?? '').indexOf(swjs) >= 0) {
        await registration.unregister();
      }
    })
  );
};
