<template>
  <v-timeline density="compact">
    <v-timeline-item v-for="(item, index) in timeLineItems" :key="index" fill-dot :dot-color="getDotColor(item)">
      <v-card>
        <v-card-title class="note">{{ item.message }}</v-card-title>
        <v-card-text>
          <v-row justify="space-between">
            <v-col class="auto">
              <div v-if="isSend(item.type)">To: {{ item.tableNo }}</div>
              <div v-else-if="isReceive(item.type)">From: {{ item.lineUserName }}（{{ item.tableNo }}）</div>
              <div>Date: {{ formateDate(item.sendTime) }}</div>
            </v-col>
            <v-col cols="auto" class="text-center pl-0">
              <v-btn
                v-if="isReceive(item.type)"
                variant="outlined"
                @click="onPushReply(item.lineUserId, item.lineUserName)"
                >返信</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script lang="ts">
import { Prop, Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as Utils from '../../common/utils';
import { MessageTimeLine } from '@/store/models/common-sys-models';

@Options({})
export default class MessageList extends Vue {
  // Props
  @Prop({ default: [] }) messages!: MessageTimeLine[];

  get timeLineItems(): MessageTimeLine[] {
    return this.messages.slice().sort((m1, m2) => {
      if (m1.sendTime > m2.sendTime) return -1;
      if (m1.sendTime < m2.sendTime) return 1;
      return 0;
    });
  }

  @Emit('onPushReply')
  onPushReply(lineUserId: string, lineUserName: string): { lineUserId: string; lineUserName: string } {
    return { lineUserId, lineUserName };
  }

  isSend(type: 'receive' | 'send' | 'notification'): boolean {
    return type === 'send';
  }

  isReceive(type: 'receive' | 'send' | 'notification'): boolean {
    return type === 'receive';
  }

  formateDate(date: Date): string {
    return Utils.dateTimeFormatBySlash(date);
  }

  getDotColor(item: MessageTimeLine): string {
    return item.type === 'send' ? 'red' : 'primary';
  }
}
</script>

<style scoped>
.note {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
