import { RMedia } from './db/r-media';
import { Timestamp } from 'firebase/firestore';

export class Media {
  public params: RMedia;

  constructor(params: RMedia) {
    this.params = params;
  }

  /** タイトルの最大文字数 */
  public static MAX_MEDIA_TITLE_LENGTH = 60;

  /** Getter */
  get id(): string {
    return this.params.id;
  }

  get title(): string {
    return this.params.title;
  }

  get contentType(): string {
    return this.params.contentType;
  }

  get path(): string {
    return this.params.path;
  }

  get size(): number {
    return this.params.size;
  }

  get isUsed(): boolean {
    return this.params.isUsed;
  }

  get lastUsed(): Timestamp {
    return this.params.lastUsed;
  }

  isPicture(): boolean {
    return this.params.contentType.startsWith('image/');
  }

  isVideo(): boolean {
    return this.params.contentType.startsWith('video/');
  }
}
