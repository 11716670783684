import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import * as Utils from '@/common/utils';
import { StoreError } from '@/common/error';
import { docs } from '@/firebase/dao';
import { Unsubscribe, deleteField, onSnapshot, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '@/firebase';
import { api } from '@/firebase/api';
import { ImportSmaregiStaffReq, ImportSmaregiStaffRes } from '@/firebase/dto/smaregi';
import { Staff } from '@/store/models/staff';
import { RStaff } from '@/store/models/db/r-staff';
import { useUsersStore } from '@/stores/users';

export const useStaffsStore = defineStore('staffs', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const staffs = ref([]) as Ref<Staff[]>;
  const subscribed = ref(false);
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const useComputables = () => {
    return {
      staffsList: computed(() => staffs.value.slice().sort((a, b) => a.params.sort - b.params.sort)),
      displayedStaffsList: computed(() =>
        staffs.value.filter((val) => val.params.display).sort((a, b) => a.params.sort - b.params.sort)
      ),
      getNextSortKey: computed(() =>
        staffs.value.length === 0
          ? 0
          : staffs.value.reduce((prev: number, cur: Staff) => Math.max(prev, cur.params.sort), 0) + 1
      ),
      isSubscribed: computed(() => subscribed.value),
    };
  };
  const computables = useComputables();

  // actionはmutationと統合して、関数
  /**
   * rStaffの監視を開始
   *
   * @param contractId
   */
  const startStaffSubscribe = (obj: { contractId: string }): void => {
    console.log('startSubscribe r_staff');

    // 会員ランクの情報をリッスン
    const unsubscriber = onSnapshot(docs.staffDoc(obj.contractId, userStore.getStoreId), async (doc) => {
      if (doc.exists()) {
        const staff = doc.data() as RStaff;
        staffs.value = Object.keys(staff).map((key) => new Staff(staff[key]));
      }
      subscribed.value = true;
    });
    dbUnsubscriber.value = unsubscriber;
  };

  /**
   * rStaffの監視を終了
   */
  const stopStaffSubscribe = (): void => {
    console.log('stopSubscribe r_staff');
    if (subscribed.value && dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
    subscribed.value = false;
  };

  /**
   * rStaffを登録
   *
   * @param obj
   */
  const addStaff = async (obj: { contractId: string; staff: RStaff }): Promise<void> => {
    try {
      // IDを割り当て
      const newId = Utils.makeNewUuid();

      const param: { [key: string]: RStaff } = { [newId]: { ...obj.staff, id: newId } };

      await setDoc(docs.staffDoc(obj.contractId, userStore.getStoreId), param, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rStaffを更新
   *
   * @param obj
   */
  const updateStaff = async (obj: { contractId: string; staff: RStaff }): Promise<void> => {
    try {
      const batch = writeBatch(db);

      // 会員ランクを登録、更新
      batch.set(docs.staffDoc(obj.contractId, userStore.getStoreId), { [obj.staff.id]: obj.staff }, { merge: true });

      // 追加、更新
      await batch.commit();
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rStaffを削除
   *
   * @param obj
   */
  const deleteStaff = async (obj: { contractId: string; staff: RStaff }): Promise<void> => {
    try {
      await updateDoc(docs.staffDoc(obj.contractId, userStore.getStoreId), {
        [obj.staff.id]: deleteField(),
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rStaffのソート順を変更
   *
   * @param obj
   */
  const sortStaff = async (obj: { contractId: string; sortedArray: Staff[] }): Promise<void> => {
    try {
      const params: { [key: string]: RStaff } = obj.sortedArray
        .map((v, i) => ({ ...v.params, sort: i }))
        .reduce((res, val): { [key: string]: RStaff } => ({ ...res, [val.id]: val }), {});

      await setDoc(docs.staffDoc(obj.contractId, userStore.getStoreId), params, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * スマレジランクインポート
   *
   * @param obj
   */
  const importSmaregiStaff = async (obj: { contractId: string }) => {
    try {
      await api.smaregi<ImportSmaregiStaffReq, ImportSmaregiStaffRes>({
        process: 'import_smaregi_staff',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    ...computables,
    startStaffSubscribe,
    stopStaffSubscribe,
    addStaff,
    updateStaff,
    deleteStaff,
    sortStaff,
    importSmaregiStaff,
  };
});
