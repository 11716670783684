import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.display,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.display) = $event)),
    persistent: "",
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        title: _ctx.title,
        class: "note"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, { class: "overflow-text" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              (!_ctx.hideNgBtn)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        variant: "outlined",
                        onClick: _ctx.onPushCancelBtn
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.ngBtnLable), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_col, {
                cols: _ctx.hideNgBtn ? 12 : 6
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    variant: "elevated",
                    block: "",
                    onClick: _ctx.onPushEnterBtn
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.okBtnLable), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["cols"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}