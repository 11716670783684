import { RQuestionnaire } from './db/r-questionnaire';
import * as Utils from '@/common/utils';

/** アプリケーションで用いるモデル **/
export type QuestionnaireModel = RQuestionnaire;

export class Questionnaire {
  constructor(public params: QuestionnaireModel) {}

  get insDateTime(): Date {
    return this.params.insDateTime.toDate();
  }

  get updDateTime(): Date {
    return this.params.updDateTime.toDate();
  }

  get displayInsDateTime(): string {
    return Utils.dateTimeFormatFromMonth(this.insDateTime);
  }

  get displayUpdDateTime(): string {
    return Utils.dateTimeFormatFromMonth(this.updDateTime);
  }
}
