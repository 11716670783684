import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { StoreError } from '@/common/error';
import { Unsubscribe, deleteField, onSnapshot, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { docs } from '@/firebase/dao';
import { getDownloadURL } from 'firebase/storage';
import { storageRef } from '@/firebase/dao';
import * as Utils from '@/common/utils';
import { Media } from '@/store/models/media';
import { ImageObject } from '@/store/models/db/common-models';
import { RMedia } from '@/store/models/db/r-media';
import { MenuImages } from '@/store/models/common-sys-models';
import { useUsersStore } from '@/stores/users';

export const useMediaStore = defineStore('medias', () => {
  const userStore = useUsersStore();

  const CONTENT_TYPES = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    jpg: 'image/jpg',
    mp4: 'video/mp4',
  };

  // stateはref変数
  const medias = ref([]) as Ref<Media[]>;
  const mediaUrls = ref({}) as Ref<MenuImages>;
  const subscribed = ref(false);
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const getMedias = computed(() =>
    medias.value.slice().sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    })
  );
  const getMediaUrls = computed(() => mediaUrls.value);

  // actionはmutationと統合して、関数
  /**
   * rMediaの監視を開始
   *
   * @param contractId
   */
  const startMediaSubscribe = (obj: { contractId: string }): void => {
    console.log('startSubscribe r_media');

    // メディアの情報をリッスン
    const unsubscriber = onSnapshot(docs.mediaDoc(obj.contractId, userStore.getStoreId), async (doc) => {
      if (doc.exists()) {
        const media = doc.data() as RMedia;
        medias.value = Object.keys(media).map((key) => new Media(media[key]));
      }
      // 画像ファイルの更新
      await loadMediaUrl({
        contractId: obj.contractId,
        mediaArray: medias.value,
      }).catch((error) => {
        console.log(error);
      });
      subscribed.value = true;
    });
    dbUnsubscriber.value = unsubscriber;
  };

  /**
   * rMediaの監視を終了
   */
  const stopMediaSubscribe = (): void => {
    console.log('stopSubscribe r_media');
    if (subscribed.value && dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
    subscribed.value = false;
  };

  /**
   * ファイル追加
   * @param obj
   */
  const addMedia = async (obj: { contractId: string; newImage: ImageObject; title: string }): Promise<string> => {
    try {
      if (!obj.newImage.url || !obj.newImage.file) {
        return '';
      }
      // ファイルのアップロード
      await Utils.setMenuImage({
        url: obj.newImage.url,
        imageFile: obj.newImage.file,
      });

      // アップロードしたファイルのメタデータを取得して登録内容を作成
      const metaData = await Utils.getMediaMetaData({ url: obj.newImage.url });
      const newId = Utils.makeNewUuid();
      const param: { [key: string]: RMedia } = {
        [newId]: {
          id: newId,
          title: obj.title,
          contentType: metaData.contentType ?? getContentType(metaData.name),
          path: metaData.fullPath,
          size: metaData.size,
          isUsed: false,
          lastUsed: Timestamp.now(),
        },
      };
      await setDoc(docs.mediaDoc(obj.contractId, userStore.getStoreId), param, { merge: true });

      return newId;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ファイル削除
   * @param obj
   */
  const deleteMedias = async (obj: { contractId: string; deleteMedias: Media[] }): Promise<void> => {
    try {
      for await (const media of obj.deleteMedias) {
        await Utils.deleteImage({ url: media.path });
        await updateDoc(docs.mediaDoc(obj.contractId, userStore.getStoreId), { [media.id]: deleteField() });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メディア画像のURLを取得
   *
   * @param obj
   */
  const loadMediaUrl = async (obj: { contractId: string; mediaArray: Media[] }) => {
    try {
      const urls = {};
      // 画像取得処理の配列を作成
      const tasks = obj.mediaArray
        .map((media) =>
          getDownloadURL(storageRef(Utils.getImagePath(media.path)))
            .then((url) => {
              urls[media.id] = {
                url: url,
              };
            })
            .catch((error) => {
              // 画像の読み込み失敗はerrorをthrowせずにコンソールに表示する。
              console.log(error);
            })
        )
        .filter((f) => f !== null);
      // 画像URL取得処理
      await Promise.all(tasks)
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          mediaUrls.value = urls;
        });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ContentTypeを取得する
   * @param fileName
   */
  const getContentType = (fileName: string): string => {
    const ext = fileName.split('.').pop();
    return ext ? CONTENT_TYPES[ext] : '';
  };

  return {
    getMedias,
    getMediaUrls,
    startMediaSubscribe,
    stopMediaSubscribe,
    addMedia,
    deleteMedias,
    loadMediaUrl,
  };
});
