import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { dense: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.coupon.id,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.coupon.id) = $event)),
              _ctx.updateCouponItem
            ],
            items: _ctx.couponItems,
            "item-title": "title",
            "item-value": "value",
            hint: "クーポンを選択してください",
            density: "compact",
            variant: "outlined",
            clearable: !_ctx.required,
            "persistent-hint": "",
            loading: _ctx.loading,
            rules: _ctx.rules.require,
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "items", "clearable", "loading", "rules", "disabled", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_v_col, {
        cols: "12",
        sm: "8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.coupon.expirationDays,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((_ctx.coupon.expirationDays) = $event)),
              _ctx.updateCouponItem
            ],
            modelModifiers: { number: true },
            label: "有効期限日数",
            prefix: "配布から",
            suffix: "日間有効",
            density: "compact",
            variant: "outlined",
            "hide-details": "auto",
            rules: _ctx.rules.moreThanZero,
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "rules", "disabled", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.isShowExpirationDays]
      ]),
      _createVNode(_component_v_col, {
        cols: "12",
        sm: "8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_checkbox, {
            modelValue: _ctx.coupon.useType,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.coupon.useType) = $event)),
              _ctx.updateCouponItem
            ],
            "true-value": _ctx.useTypeMultiTime,
            "false-value": _ctx.useTypeOneTime,
            disabled: !_ctx.existEndDate || _ctx.disabled,
            label: "利用回数の制限なし",
            "hide-details": "auto"
          }, null, 8, ["modelValue", "true-value", "false-value", "disabled", "onUpdate:modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}