/** イートイン、テイクアウト操作の種類 */
export enum orderType {
  /** 注文 */
  order = 'order',
  /** LINE注文 */
  lineOrder = 'lineOrder',
  /** waiter注文 */
  waiterOrder = 'waiterOrder',
  /** 取消 */
  cancel = 'cancel',
  /** チャージ自動登録 */
  autoCharge = 'autoChargeRegist',
  /** 数量変更 */
  change = 'change',
}

/** 通販の受注ステータス */
export enum orderStatus {
  /** 未決済 */
  inPayment = '0',
  /** 受注 */
  received = '1',
  /** 発送準備 */
  shipping = '2',
  /** 未入金 */
  pending = '8',
  /** 発送済 */
  done = '9',
  /** キャンセル */
  cancel = '99',
  /** 返金 */
  refund = '91',
}

export enum salesType {
  /** イートイン（注文くん 店内） */
  eatin = 'eatin',
  /** イートイン（注文くん 店外） */
  dineIn = 'dineIn',
  /** テイクアウト */
  takeout = 'takeout',
  /** テイクアウト2 */
  takeout2 = 'takeout2',
  /** 駐車場受取 */
  curbside = 'curbside',
  /** デリバリー */
  delivery = 'delivery',
}

export enum categoryType {
  /** イートイン */
  eatin = 'eatin',
  /** テイクアウト */
  takeout = 'takeout',
  /** 通販 */
  ec = 'ec',
}

export enum transactionType {
  /** イートイン */
  eatin = 'eatin',
  /** 持ち帰り */
  picking = 'picking',
  /** 通販 */
  ec = 'ec',
  /** 手動 */
  manual = 'manual',
  /** その他 */
  other = 'other',
}

export enum paymentType {
  /** 注文くん */
  chumon = 'chumon',
  /** 通販くん */
  ec = 'ec',
}

export enum userInfoItem {
  /** 電話番号 */
  tel = 'tel',
  /** 名前 */
  name = 'name',
  /** 郵便番号 */
  postalCode = 'postalCode',
  /** 住所 */
  address = 'address',
  /** その他1 */
  other1 = 'other1',
  /** その他2 */
  other2 = 'other2',
  /** その他3 */
  other3 = 'other3',
}

export enum paymentStatus {
  /** 未決済 */
  beforePayment = '0',
  /** 支払中 */
  inPayment = '1',
  /** 支払済 */
  paid = '2',
}

export enum paymentMethods {
  cash = '現金',
  creditCard = 'クレジット',
  creditCardSp = 'クレジットSP',
  qr = 'QR',
  paypay = 'PayPay',
  csp = 'コンビニ払い',
  cod = '代引き',
  bankTransfer = '銀行振込',
  invoice = '請求書払い',
  other = 'その他',
}

export enum deliveryCompany {
  yamato = 'yamato',
  sagawa = 'sagawa',
  seino = 'seino',
  jp = 'jp',
  mail = 'mail',
}

export enum rankUpType {
  count = '0',
  amount = '1',
}

export enum miniAppPaymentMethods {
  cash = 'cash',
  creditCard = 'creditCard',
  previousCreditCard = 'previousCreditCard',
  previousCardSquare = 'previousCardSquare',
  creditCardSp = 'creditCardSp',
  creditCardSquare = 'creditCardSquare',
  creditCardGmo = 'creditCardGmo',
  paypay = 'PayPay',
  paypayGmo = 'PayPayGMO',
  cod = 'cod',
  csp = 'csp',
  bankTransfer = 'bankTransfer',
  invoice = 'invoice',
}

export enum chargeAutoRegistType {
  guests = 'guests',
  table = 'table',
}

export enum accountType {
  moduleChannel = 'module_channel',
  messagingApi = 'messaging_api',
}

export enum contractPaymentMethods {
  creditCard = 'creditCard',
  bankTransfer = 'bankTransfer',
  smaregi = 'smaregi',
  agent = 'agent',
  other = 'other',
}

export enum sellerType {
  chooseMonster = 'choose_monster',
  agent = 'agent',
}

export enum appType {
  smaregi = 'smaregi', // スマレジ
}

export enum productId {
  none = 'none', // 契約なし
  eatinChumonKunStandard = 'eatinChumonKunStandard', // スタンダード、プラス、プレミアムプラン
  eatinChumonKunWaiter = 'eatinChumonKunWaiter', // スタンダード、プラス、プレミアムプラン、ウェイター
  eatinChumonKun = 'eatinChumonKun', // スタンダード、プラス、プレミアムプラン
  takeoutChumonKunLight = 'takeoutChumonKunLight', // ライトプラン
  takeoutChumonKunModuleChannel = 'takeoutChumonKunModuleChannel', // ライトプラン
  takeoutChumonKun = 'takeoutChumonKun', // スタンダードプラン
  takeoutChumonKunPlus = 'takeoutChumonKunPlus', // プラスプラン
  waitingMachiko = 'waitingMachiko', // 順番待ちスタンダードプラン
  waitingMachikoPro = 'waitingMachikoPro', // 順番待ちプロプラン
  membership = 'membership', // 常連くるみちゃん
  ecKun = 'ecKun', //通販くん
}

export enum plan {
  none = 'none', // 契約なし
  eatinChumonKunStandard = 'eatinChumonKunStandard', // スタンダードプラン
  eatinChumonKunWaiter = 'eatinChumonKunWaiter', // for Waiterプラン
  eatinChumonKun = 'eatinChumonKun', // プラスプラン
  takeoutChumonKunModuleChannel = 'takeoutChumonKunModuleChannel', // ライトプラン（MP）
  takeoutChumonKunLight = 'takeoutChumonKunLight', // ライトプラン（月額）
  takeoutChumonKun = 'takeoutChumonKun', // スタンダードプラン（月額）
  waitingMachiko = 'waitingMachiko', // 順番待ち
  waitingMachikoPro = 'waitingMachikoPro', // 順番待ちプロプラン
  membership = 'membership', // 常連くるみちゃん
  ecKun = 'ecKun', //通販くん
  boostPack = 'boostPack',
  monsterPack = 'monsterPack',
  chumonKun = 'chumonKun',
}

export enum smaregiAppId {
  chumonKunPlugin = 'chumonKunPlugin',
  membershipPlugin = 'membershipPlugin',
  anytimeChumonKun = 'anytimeChumonKun',
  anywhereChumonKun = 'anywhereChumonKun',
  membership = 'membership',
}

export enum pos {
  none = 'none',
  blaynPos = 'blayn',
  smaregi = 'smaregi',
}

export enum moduleMode {
  active = 'active',
  standby = 'standby',
}

export enum waitingStatus {
  waiting = 'waiting',
  reminded = 'reminded',
  calling = 'calling',
  hold = 'hold',
  cancel = 'cancel',
  complete = 'complete',
}

export enum division {
  normal = 'normal',
  optional = 'optional',
}

export enum printJobType {
  waiting = 'waiting',
  entry = 'entry',
}

export enum messageAutomationType {
  thanks = 'thanks',
  memberRegist = 'memberRegist',
  birthday = 'birthday',
  onTable = 'onTable',
  inWaiting = 'inWaiting',
  waitingCanceled = 'waitingCanceled',
  customerFilter = 'customerFilter',
}

export enum Permission {
  admin = 'admin',
  normal = 'normal',
  member = 'member',
}

export enum taxType {
  normal = 'normal', // 標準税率
  reduced = 'reduced', // 軽減税率
  none = 'none', // 非課税
}

export enum taxRoundingType {
  truncate = 'truncate', // 切り捨て
  round = 'round', // 四捨五入
  ceil = 'ceil', // 切り上げ
}

export enum visibilitySettingType {
  none = 'none', // 非公開
  all = 'all', // 全員
  members = 'members', // 会員限定
  rank = 'rank', // 特定ランク
}

export enum stockCondition {
  /** 販売可能 */
  orderable = 'orderable',
  /** 売切れ */
  soldout = 'soldout',
}

export enum notificationType {
  /** リリース情報 */
  release = 'release',
  /** お知らせ情報 */
  notification = 'notification',
}

export enum stockType {
  /** 日別在庫 */
  all = 'all',
  /** 時間帯別在庫 */
  hourly = 'hourly',
}

export enum waitingImageType {
  shop = 'shop',
  company = 'company',
}

export enum searchCustomerType {
  all = 'all',
  nonMember = 'nonMember',
  member = 'member',
}

export enum storeType {
  store = 'store',
  headquarters = 'headquarters',
}

export enum selectStoreMode {
  login = 'login',
  change = 'change',
}

export enum messageType {
  notification = 'notification',
  message = 'message',
}

/** クーポンの利用タイプ */
export enum useType {
  /** 1回限り */
  oneTime = 'oneTime',
  /** 複数回 */
  multiTime = 'multiTime',
}

/** クーポンの特典タイプ */
export enum awardType {
  amountDiscount = '1', // 値引き（旧データ用）
  percentDiscount = '2', // 割引（旧データ用）
  productOffer = '3', // 商品付与（旧データ用）
  C1 = 'C1', // 値引き（新データ用）
  C2 = 'C2', // 割引（新データ用）
  G1 = 'G1', // 商品付与（新データ用）
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  O1 = 'O1',
}

/** クーポンのデータ元 */
export enum couponDataSource {
  /** 自システム */
  internal = '1',
  /** スマレジ */
  smaregi = '2',
}
