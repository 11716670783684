import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      // 1分おきに更新を確認
      setInterval(() => registration.update(), 60 * 1000);
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');

      // window.alert('アプリの新しいバージョンが見つかりました。このダイアログが閉じた後にアプリは自動で更新されます。');
      // window.location.reload();
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      registration.update();
      // window.location.reload();
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
