import { OrderTimeframe, RMenuCategories, SubCategories } from '@/store/models/db/r-menu-categories';
import * as Utils from '@/common/utils';
import { Table } from './table';
import { categoryType, visibilitySettingType } from '@/common/appCode';
import { Menu } from './menu';

export interface MenuCategoryModel extends RMenuCategories {
  key: string;
}

export interface SubCategory extends SubCategories {
  key: string;
}

export class MenuCategory {
  public params: MenuCategoryModel;

  constructor(
    params: RMenuCategories,
    option: {
      key;
    }
  ) {
    this.params = {
      key: option.key,
      ...params,
    };
  }

  /** 登録できるカテゴリの最大数の取得 */
  public static MAX_NUMBER_OF_CATEGORY_REGISTERED = 100;
  /** 登録できるサブカテゴリの最大数の取得 */
  public static MAX_NUMBER_OF_SUB_CATEGORY_REGISTERED = 6;

  /** カテゴリ名の登録可能最大文字数 */
  public static MAX_CATEGORY_NAME_LENGTH = 20;
  /** サブカテゴリ名の登録可能最大文字数 */
  public static MAX_SUB_CATEGORY_NAME_LENGTH = 20;

  /** 多言語化の初期値 */
  static get DEFALUT_MULTILINGUAL(): { ja: string; en: string; zh: string; ko: string } {
    return {
      ja: '',
      en: '',
      zh: '',
      ko: '',
    };
  }

  get categoryTypes(): string[] {
    return this.params.category_type ?? [];
  }

  get visibility(): visibilitySettingType {
    return !this.params.visibility && this.isStoreOnly
      ? visibilitySettingType.none
      : (this.params.visibility ?? visibilitySettingType.all);
  }

  get hasCategoryTypes(): boolean {
    return this.categoryTypes.length >= 1;
  }

  get type(): categoryType {
    // レガシー対応
    if (!this.params.type) {
      return this.params.isTakeout ? categoryType.takeout : categoryType.eatin;
    }
    return this.params.type;
  }

  get alias(): boolean {
    return this.params.alias ?? false;
  }

  get isEatin(): boolean {
    return this.type === categoryType.eatin;
  }

  get isTakeout(): boolean {
    return this.type === categoryType.takeout;
  }

  get isEc(): boolean {
    return this.type === categoryType.ec;
  }

  get isStoreOnly(): boolean {
    return this.params.visibility
      ? this.params.visibility === visibilitySettingType.none
      : Boolean(this.params.store_only);
  }

  get isMembersOnly(): boolean {
    return this.params.visibility === visibilitySettingType.members;
  }

  isInOrderTimeframe(orderTimeframe: OrderTimeframe, date: Date): boolean {
    return Utils.isDateSameOrAfter(
      Utils.parseDate(`${Utils.dateFormatByHyphen(new Date())}T${orderTimeframe.start}`),
      Utils.parseDate(`${Utils.dateFormatByHyphen(new Date())}T${orderTimeframe.end}`)
    )
      ? // 開始時刻・終了時刻が逆転している場合
        // 終了は翌日扱い
        Utils.isBetween(
          date,
          Utils.parseDate(`${Utils.dateFormatByHyphen(date)}T${orderTimeframe.start}`),
          Utils.parseDate(`${Utils.dateFormatByHyphen(Utils.addDays(date, 1))}T00:00`)
        ) ||
          Utils.isBetween(
            date,
            Utils.parseDate(`${Utils.dateFormatByHyphen(date)}T00:00`),
            Utils.parseDate(`${Utils.dateFormatByHyphen(date)}T${orderTimeframe.end}`)
          )
      : Utils.isDateSameOrAfter(date, Utils.parseDate(`${Utils.dateFormatByHyphen(date)}T${orderTimeframe.start}`)) &&
          Utils.isDateSameOrBefore(date, Utils.parseDate(`${Utils.dateFormatByHyphen(date)}T${orderTimeframe.end}`));
  }

  isSameOrBeforeSpecifyDate(targetDate: Date): boolean {
    return (
      !this.params.specifyDate ||
      (this.params.specifyDate.endDate
        ? Utils.isDateSameOrBefore(
            Utils.dateTimeFormatYYYYMMDD(targetDate),
            Utils.dateTimeFormatYYYYMMDD(this.params.specifyDate.endDate)
          )
        : Utils.isDateSameOrBefore(
            Utils.dateTimeFormatYYYYMMDD(targetDate),
            Utils.dateTimeFormatYYYYMMDD(this.params.specifyDate.date)
          ))
    );
  }

  isBetweenSpecifyDate(targetDate: Date): boolean {
    return (
      !this.params.specifyDate ||
      (this.params.specifyDate.endDate
        ? Utils.isBetween(
            Utils.dateTimeFormatYYYYMMDD(targetDate),
            Utils.dateTimeFormatYYYYMMDD(this.params.specifyDate.date),
            Utils.dateTimeFormatYYYYMMDD(this.params.specifyDate.endDate)
          )
        : Utils.isDateSame(
            Utils.dateTimeFormatYYYYMMDD(this.params.specifyDate.date),
            Utils.dateTimeFormatYYYYMMDD(targetDate)
          ))
    );
  }

  isBeforeDeadline(targetDate: Date, takeoutOpenTime: string): boolean {
    // 受取日時が注文期限期限内かどうか
    return (
      !this.params.preOrderDeadline ||
      Utils.isDateSameOrAfter(
        targetDate,
        Utils.getEarliestPickUpTimeForPreOrder(
          this.params.preOrderDeadline?.dayBefore ?? 0,
          this.params.preOrderDeadline?.time ?? '',
          takeoutOpenTime
        )
      )
    );
  }

  public isUserEatinOrderAvailable(date: Date, table: Table | null, showUnshownCategory: boolean): boolean {
    // 食べ飲み放題が有効
    const isInTimeCategory =
      Boolean(table?.isCategoryTypeMatched(this.categoryTypes)) &&
      (!showUnshownCategory || Boolean(table?.isEveryCategoryTypeAvailable(this.categoryTypes))); //  対象の食べ飲み放題が設定されている

    // 非表示設定が有効
    const isInUnshownCategory =
      Boolean(table?.isCategoryTypeMatched(this.params.unshownCategoryType)) &&
      (!showUnshownCategory || Boolean(table?.isEveryCategoryTypeAvailable(this.params.unshownCategoryType))); //  非表示対象の食べ飲み放題が設定されている

    return (
      this.isEatin && // イートイン
      !this.isStoreOnly && // 店舗のみではない
      (!this.hasCategoryTypes || isInTimeCategory) && // 食べ飲み放題が設定されていないカテゴリー or 対象の食べ飲み放題が有効
      !isInUnshownCategory && // 非表示設定が有効
      // 表示時間帯設定があり、表示時間帯内
      (!this.params.orderTimeframe || this.isInOrderTimeframe(this.params.orderTimeframe, date))
    );
  }

  /**
   *  サブカテゴリの配列の取得
   */
  public subCategoryArray(): SubCategory[] {
    return Object.keys(this.params.sub_categories)
      .map((key) => {
        return {
          key: key,
          name: this.params.sub_categories[key].name,
          multilingualNames: this.params.sub_categories[key].multilingualNames,
          description: this.params.sub_categories[key].description,
          multilingualDescription: this.params.sub_categories[key].multilingualDescription,
          sort_key: this.params.sub_categories[key].sort_key,
          aliasMenus: this.params.sub_categories[key].aliasMenus ?? [],
        };
      })
      .sort((sc1, sc2) => {
        if (sc1.sort_key > sc2.sort_key) return 1;
        if (sc1.sort_key < sc2.sort_key) return -1;
        return 0;
      });
  }

  /**
   * サブカテゴリのリンクメニューを返す
   * @param subCategoryNo サブカテゴリ番号
   * @param menuArray メニュー配列
   */
  public aliasMenuArray(subCategoryNo: string, menuArray: Menu[]): Menu[] {
    const aliasMenuIds = this.subCategoryArray()
      .find((v) => v.key === subCategoryNo)
      ?.aliasMenus?.map((v) => v)
      .sort((a, b) => a.sortKey - b.sortKey)
      .map((v) => v.menuId);

    return aliasMenuIds
      ? aliasMenuIds.reduce((acc: Menu[], id: string): Menu[] => {
          const menu = menuArray.find((v) => v.params.menuId === id);
          return menu ? [...acc, menu] : acc;
        }, [])
      : [];
  }
}
