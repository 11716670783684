import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pa-3 text-caption" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-black text-h6" }
const _hoisted_5 = {
  key: 0,
  class: "text-black text-h6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_message_dialog = _resolveComponent("message-dialog")!
  const _component_select_staff_dialog = _resolveComponent("select-staff-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
      class: "bg-grey-darken-2"
    }, {
      append: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, "Ver. " + _toDisplayString(_ctx.appVersion), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dark: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { onClick: _ctx.onPushStore }, _createSlots({
              prepend: _withCtx(() => [
                _createVNode(_component_v_avatar, null, {
                  default: _withCtx(() => [
                    (_ctx.isEatInStore)
                      ? (_openBlock(), _createBlock(_component_v_img, {
                          key: 0,
                          src: "img/anytime_icon.jpg"
                        }))
                      : (_ctx.isTakeoutStore)
                        ? (_openBlock(), _createBlock(_component_v_img, {
                            key: 1,
                            src: "img/anywhere_icon.jpg"
                          }))
                        : (_ctx.isEcStore)
                          ? (_openBlock(), _createBlock(_component_v_img, {
                              key: 2,
                              src: "img/ec_icon.jpg"
                            }))
                          : (_ctx.isWaitingStore)
                            ? (_openBlock(), _createBlock(_component_v_img, {
                                key: 3,
                                src: "img/waiting_icon.png"
                              }))
                            : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, { class: "text-body-1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.companyName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item_subtitle, {
                  class: "text-caption note",
                  dense: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.is2ndGenData && _ctx.storeId ? _ctx.storeName : _ctx.time), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, [
              (_ctx.isStoreSwitchable)
                ? {
                    name: "append",
                    fn: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        icon: "keyboard_arrow_right",
                        size: "small",
                        variant: "text"
                      })
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["onClick"]),
            ((_ctx.isEatInStore || _ctx.isTakeoutStore) && !_ctx.isHeadquarterOf2ndGen)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 0,
                  density: "compact",
                  onClick: _withModifiers(_ctx.onPushStaff, ["stop"])
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "large" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("person")
                      ])),
                      _: 1
                    })
                  ]),
                  append: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("keyboard_arrow_right")
                      ])),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_subtitle, { class: "text-caption" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("担当者")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item_title, { class: "text-subtitle-1" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentStaffName), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_divider, { color: "grey" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.individualPage, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.name
              }, [
                _createVNode(_component_v_list_item, {
                  onClick: ($event: any) => (_ctx.onPushItem(item)),
                  link: ""
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.icon), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_v_divider, { color: "grey" })
              ], 64))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupes, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.name
              }, [
                _createVNode(_component_v_list_group, null, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_list_item, _mergeProps({ ref_for: true }, props), {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.icon), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (page, i) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: i,
                        onClick: ($event: any) => (_ctx.onPushItem(page)),
                        link: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(page.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_divider, { color: "grey" })
              ], 64))
            }), 128)),
            _createVNode(_component_v_divider, { color: "grey" }),
            _createVNode(_component_v_list_item, { onClick: _ctx.onPushHelpDocument }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("mdi:mdi-help-circle")
                  ])),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("ご利用ガイド")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_divider, { color: "grey" }),
            _createVNode(_component_v_list_item, { onClick: _ctx.onPushLogout }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("exit_to_app")
                  ])),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("ログアウト")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_divider, { color: "grey" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_app_bar, {
      color: "primary",
      elevation: "1",
      density: "compact"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_toolbar_title, { class: "text-center" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        (!_ctx.isProduction)
          ? (_openBlock(), _createBlock(_component_v_chip, {
              key: 0,
              theme: "light",
              size: "small",
              label: "",
              variant: "flat"
            }, {
              default: _withCtx(() => [
                (_ctx.isStaging)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "STAGING"))
                  : (_ctx.isLocal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "LOCAL"))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.isMessageDisabled())
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              icon: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPushMessage()))
            }, {
              default: _withCtx(() => [
                (_ctx.messageCount >= 1)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      color: "accent"
                    }, {
                      badge: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.messageCount), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("mdi:mdi-message-text")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("mdi:mdi-message-text")
                      ])),
                      _: 1
                    }))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, {
          icon: "",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onPushRefresh()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("refresh")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        (!_ctx.isHeadquarter)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              icon: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onPushSetting()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("settings")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_message_dialog, {
      ref: "messageDialog",
      messages: _ctx.messages,
      onError: _ctx.errorPropagation
    }, null, 8, ["messages", "onError"]),
    _createVNode(_component_select_staff_dialog, {
      ref: "selectStaffDialog",
      onChangeStaff: _ctx.changeStaff,
      onError: _ctx.errorPropagation
    }, null, 8, ["onChangeStaff", "onError"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.showSnackBar,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showSnackBar) = $event)),
      color: "message",
      location: "top",
      vertical: "",
      timeout: 30000,
      class: "note"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, _mergeProps({
          theme: "dark",
          variant: "text",
          class: "text-black"
        }, _ctx.$attrs, {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSnackBar = false))
        }), {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createTextVNode("閉じる")
          ])),
          _: 1
        }, 16)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.messageText), 1),
        _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
        (_ctx.messageFrom)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "From : " + _toDisplayString(_ctx.messageFrom), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.showUpdateSnackBar,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showUpdateSnackBar) = $event)),
      location: "bottom",
      vertical: "",
      timeout: -1
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, _mergeProps({
          theme: "dark",
          variant: "text",
          class: "text-black",
          color: "red"
        }, _ctx.$attrs, {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onPushUpdate()))
        }), {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode("更新")
          ])),
          _: 1
        }, 16)
      ]),
      default: _withCtx(() => [
        _cache[23] || (_cache[23] = _createTextVNode(" アプリの新しいバージョンがあります。更新を押してアプリを最新バージョンに更新してください。 "))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}