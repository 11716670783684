<template>
  <v-snackbar
    v-model="display"
    :color="color"
    :timeout="timeout"
    class="mb-4"
    content-class="text-center font-weight-bold"
    :absolute="absolute"
  >
    {{ message }}
  </v-snackbar>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class SuccessDialog extends Vue {
  /** Props **/
  @Prop({ default: false }) absolute!: boolean;

  /** Data **/
  display = false;
  message = '';
  color = '';
  timeout = 3000;

  /** Methods **/
  open(obj: { message: string; color?: string; timeout?: number }): void {
    this.message = obj.message;
    this.color = obj.color ?? 'success';
    this.timeout = obj.timeout ?? 3000;
    this.display = true;
  }

  close(): void {
    this.color = 'success';
    this.display = false;
  }
}
</script>
