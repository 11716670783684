<template>
  <v-dialog v-model="display" scrollable>
    <v-card height="100%" title="LINEメッセージ作成">
      <v-card-text>
        <v-form v-model="isVaild">
          <v-row>
            <v-col cols="12" lg="9" md="8" sm="7">
              <v-row no-gutters>
                <v-col cols="12" v-if="process !== 'edit'">
                  <v-text-field
                    v-if="!toLineUser || toLineUser.length > 0"
                    :model-value="labelSending"
                    density="compact"
                    variant="outlined"
                    disabled
                  ></v-text-field>
                  <v-select
                    v-else
                    v-model="to"
                    :items="tables"
                    item-title="tableNo"
                    item-value="tableId"
                    label="送信先"
                    chips
                    variant="outlined"
                    multiple
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @mousedown.prevent @click="toggle()">
                        <v-list-item-action>
                          <v-icon :color="to.length === tables.length ? 'primary' : ''">{{ icon() }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-title> All </v-list-item-title>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" v-for="(data, index) in messageData" :key="index">
                  <v-card variant="outlined" class="mb-3">
                    <v-tabs
                      v-model="tabNo[index]"
                      slider-color="primary"
                      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                      @update:model-value="onTabChange(index)"
                    >
                      <v-tab ripple><v-icon>mdi:mdi-playlist-edit</v-icon>テキスト</v-tab>
                      <v-tab ripple><v-icon>camera</v-icon>画像</v-tab>
                      <v-tab ripple><v-icon>video_call</v-icon>動画</v-tab>
                      <v-tab ripple><v-icon>dynamic_form</v-icon>アンケート</v-tab>
                      <v-tab ripple v-if="accountSetting.isMembershipStore"
                        ><v-icon>mdi:mdi-ticket-outline</v-icon>クーポン</v-tab
                      >
                      <v-spacer></v-spacer>
                      <v-btn class="mt-1 mr-2" icon="close" variant="text" @click="onDeleteMessageData(index)"></v-btn>
                    </v-tabs>
                    <v-window v-model="tabNo[index]">
                      <v-window-item>
                        <v-card flat>
                          <v-card-text class="py-1">
                            <v-textarea v-model="data.text" :ref="'textarea' + index" variant="outlined"></v-textarea>
                            <v-btn variant="outlined" @click.stop="onPushInsertUserName(data.text, index)"
                              >会員名を挿入</v-btn
                            >
                          </v-card-text>
                          <v-card-text class="pt-0 text-caption text-red">
                            <span
                              >※
                              敬称はつきませんので、必要な場合は「{CustomerName}様」のように敬称を直接入力してください</span
                            >
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <v-window-item>
                        <v-card flat>
                          <v-card-text class="pb-0">
                            <v-row
                              class="mb-2"
                              dense
                              v-for="(pictureData, pictureIndex) in data.picture"
                              :key="`${index}-${pictureIndex}`"
                            >
                              <v-col cols="3">
                                <v-select
                                  v-model="pictureData.type"
                                  :items="URL_TYPE"
                                  item-title="text"
                                  item-value="value"
                                  @update:model-value="onUrlTypeChange(data, pictureIndex)"
                                  hide-details="auto"
                                  density="compact"
                                  variant="outlined"
                                />
                              </v-col>
                              <v-col cols="8" v-if="pictureData.type === URL_TYPE_INPUT">
                                <v-text-field
                                  v-model="pictureData.url"
                                  :rules="rules.pictureExt"
                                  variant="outlined"
                                  density="compact"
                                  hide-details="auto"
                                  label="画像URL"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="8" v-else-if="pictureData.type === URL_TYPE_SELECT">
                                <v-row dense>
                                  <v-col cols="9">
                                    <v-select
                                      v-model="pictureData.mediaId"
                                      :items="selectPictureItems"
                                      item-title="text"
                                      item-value="value"
                                      label="アップロード画像"
                                      hide-details="auto"
                                      density="compact"
                                      variant="outlined"
                                    />
                                  </v-col>
                                  <v-col cols="3" class="text-right">
                                    <v-btn variant="outlined" @click="onPushAddPictureDialog(index, pictureIndex)"
                                      >画像登録</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="1" class="text-center">
                                <v-btn
                                  class="mt-1 mr-2"
                                  icon="close"
                                  variant="text"
                                  @click="onPushDeletePicture(index, pictureIndex)"
                                >
                                </v-btn>
                              </v-col>
                              <v-col cols="8" offset="3" v-if="data.picture.length > 1">
                                <v-text-field
                                  v-model="pictureData.linkUrl"
                                  hide-details="auto"
                                  variant="outlined"
                                  density="compact"
                                  label="リンクURL"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-btn variant="outlined" @click="onPushAddPicture(index)">画像追加</v-btn>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <v-window-item>
                        <v-card flat>
                          <v-card-text class="pb-0">
                            <v-text-field
                              v-model="data.movieUrl"
                              :rules="rules.movieExt"
                              variant="outlined"
                              density="compact"
                              label="動画URL"
                            ></v-text-field>
                            <v-text-field
                              v-model="data.movieThumbnailUrl"
                              :rules="rules.pictureExt"
                              variant="outlined"
                              density="compact"
                              label="サムネイル画像URL"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <v-window-item>
                        <v-card flat>
                          <v-card-text class="pb-0">
                            <v-select
                              v-model="data.questionnaireId"
                              :items="selectQuestionnaireItems"
                              item-title="text"
                              item-value="value"
                              density="compact"
                              variant="outlined"
                            />
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <v-window-item v-if="accountSetting.isMembershipStore">
                        <v-card flat>
                          <v-card-text>
                            <select-coupon
                              :couponId="data.couponId"
                              :useType="data.couponUseType"
                              :expirationDays="data.couponExpirationDays"
                              :isShowExpirationDays="true"
                              @updateCouponItem="(params) => updateCouponItem(params, index)"
                            ></select-coupon>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                    </v-window>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    variant="outlined"
                    :disabled="disabledAddMessageBtn()"
                    @click="onAddMessageData"
                    ><v-icon>add</v-icon>メッセージを追加</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <v-card variant="outlined" height="100%">
                <message-preview :avatarImgUrl="avatarImgUrl" :messageContents="lineMessageContent"></message-preview>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="4" sm="2" offset="4" offset-sm="8" class="pt-4">
            <v-btn variant="outlined" block @click="onPushCloseBtn">キャンセル</v-btn>
          </v-col>
          <v-col cols="4" sm="2" class="pt-4">
            <v-btn
              v-if="process === 'edit'"
              :disabled="disabledSaveBtn"
              color="primary"
              variant="elevated"
              block
              @click="onPushSaveBtn"
              ><v-icon>mdi:mdi-content-save</v-icon>保存</v-btn
            >
            <v-btn
              v-else
              :disabled="disabledSubmitBtn"
              color="primary"
              variant="elevated"
              block
              @click="onPushSubmitMessageBtn"
              ><v-icon>mdi:mdi-send</v-icon>送信</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Dialog -->
  <confirmation-dialog
    ref="submitMessageConfirmationDialog"
    :title="'メッセージの送信'"
    :message="'メッセージを送信してもよろしいですか?'"
    @onPushEnterBtn="onPushSubmitMessageConfirmationDialogEnterBtn"
  />
  <add-media-dialog ref="addMediaDialog" @onPushEnterBtn="onPushAddMeiaDialogEnterBtn" />
  <success-dialog ref="successDialog" />
</template>

<script lang="ts">
import { Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import ConfirmationDialog from '../../components/molecules/ConfirmationDialog.vue';
import AddMediaDialog, { AddMediaDialogParams } from '../../components/molecules/AddMediaDialog.vue';
import SuccessDialog from '@/components/molecules/SuccessDialog.vue';
import { useAppStore } from '@/stores/app';
import { useUsersStore } from '@/stores/users';
import { useMediaStore } from '@/stores/media';
import { useMessagesStore } from '@/stores/messages';
import { useQuestionnairesStore } from '@/stores/questionnaires';
import { useTablesStore } from '@/stores/tables';
import { LineMessageContent, LineMessagePictureContent, LineUser } from '../../store/models/db/common-models';
import { Settings } from '@/store/models/settings';
import { Table } from '@/store/models/table';
import { GuestLog } from '@/store/models/db/r-tables';
import { Questionnaire } from '@/store/models/questionnaire';
import { couponDataSource, messageAutomationType, useType } from '@/common/appCode';
import { CommonSettings } from '@/common/common-settings';
import { AccountSetting } from '@/store/models/account-setting';
import { TextareaHTMLAttributes } from 'vue';
import * as Utils from '@/common/utils';
import SelectCoupon, { CouponParams } from '@/components/molecules/SelectCoupon.vue';
import MessagePreview from '@/components/molecules/MessagePreview.vue';

type contentType = 'text' | 'image' | 'video' | 'questionnaire' | 'coupon';

export interface MessageData {
  type: contentType;
  text: string;
  picture: LineMessagePictureContent[];
  movieUrl: string;
  movieThumbnailUrl: string;
  questionnaireId: string;
  couponId: string;
  couponName: string;
  couponUseType: useType;
  couponExpirationDays: number | null;
  couponDataSource: couponDataSource;
}

@Options({
  components: {
    'confirmation-dialog': ConfirmationDialog,
    'add-media-dialog': AddMediaDialog,
    'success-dialog': SuccessDialog,
    'select-coupon': SelectCoupon,
    'message-preview': MessagePreview,
  },
  emits: ['onPushSubmitContents', 'onPushSaveBtn'],
})
export default class LineMessage extends Vue {
  /** Data **/
  appStore = useAppStore();
  userStore = useUsersStore();
  mediaStore = useMediaStore();
  messagesStore = useMessagesStore();
  questionnairesStore = useQuestionnairesStore();
  tablesStore = useTablesStore();

  readonly MESSAGE_TYPE_TEXT = 0;
  readonly MESSAGE_TYPE_PICTURE = 1;
  readonly MESSAGE_TYPE_MOVIE = 2;
  readonly MESSAGE_TYPE_QUESTIONNAIRE = 3;
  readonly MESSAGE_TYPE_COUPON = 4;
  readonly URL_TYPE_INPUT = 'input';
  readonly URL_TYPE_SELECT = 'select';
  readonly URL_TYPE = [
    { text: 'URL入力', value: 'input' },
    { text: 'メディア選択', value: 'select' },
  ];

  display = false;
  process: 'send' | 'reply' | 'edit' = 'send';
  type: messageAutomationType = messageAutomationType.thanks;
  isVaild = false;
  to: string[] = [];
  toLineUser: LineUser[] | null = [];
  toTableNo = '';
  tabNo = [0];
  messageData: MessageData[] = [
    {
      type: 'text',
      text: '',
      picture: [
        {
          type: this.URL_TYPE_INPUT,
          url: '',
          mediaId: '',
          linkUrl: '',
        },
      ],
      movieUrl: '',
      movieThumbnailUrl: '',
      questionnaireId: '',
      couponId: '',
      couponName: '',
      couponUseType: useType.oneTime,
      couponExpirationDays: null,
      couponDataSource: couponDataSource.internal,
    },
  ];
  dataIndex = 0;
  pictureIndex = 0;
  rules: { [key: string]: Array<(key) => boolean | string> } = {
    pictureExt: [
      (value: string): boolean | string =>
        !value || value.search(/.+\.(jpg|jpeg|png)$/) > -1 || '画像はJPG、PNGを指定してください。',
    ],
    movieExt: [
      (value: string): boolean | string => !value || value.search(/.+\.mp4$/) > -1 || '動画はMP4を指定してください。',
    ],
    couponName: [(value: string): boolean | string => Utils.maxLength(value, 40) || `40文字以内で入力してください。`],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any = null;

  /** Refs **/
  @Ref('submitMessageConfirmationDialog') submitMessageConfirmationDialog!: ConfirmationDialog;
  @Ref('addMediaDialog') addMediaDialog!: AddMediaDialog;
  @Ref('successDialog') successDialog!: SuccessDialog;

  /** Computed **/
  get settings(): Settings {
    return this.userStore.getSettings;
  }

  get accountSetting(): AccountSetting {
    return this.userStore.getAccountSetting;
  }

  get avatarImgUrl(): string {
    const defaultImg = 'img/anytime_icon.jpg';
    return this.userStore.getBrandLogoImage || defaultImg;
  }

  get allTables(): { [key: string]: Table } {
    return this.tablesStore.getTables;
  }

  get questionnairesList(): Questionnaire[] {
    return this.questionnairesStore.questionnairesList;
  }

  get lineMessageContent(): LineMessageContent[] {
    return this.messageData.reduce((res: LineMessageContent[], val: MessageData, index: number) => {
      if (this.tabNo[index] === this.MESSAGE_TYPE_TEXT) {
        res.push({ type: 'text', message: val.text });
      } else if (this.tabNo[index] === this.MESSAGE_TYPE_PICTURE) {
        res.push({ type: 'image', picture: val.picture });
      } else if (this.tabNo[index] === this.MESSAGE_TYPE_MOVIE) {
        res.push({ type: 'video', originalUrl: val.movieUrl, thumbnailUrl: val.movieThumbnailUrl });
      } else if (this.tabNo[index] === this.MESSAGE_TYPE_QUESTIONNAIRE) {
        res.push({ type: 'questionnaire', questionnaireId: val.questionnaireId });
      } else if (this.tabNo[index] === this.MESSAGE_TYPE_COUPON) {
        res.push({
          type: 'coupon',
          couponId: val.couponId,
          couponName: val.couponName,
          couponUseType: val.couponUseType,
          couponExpirationDays: val.couponExpirationDays,
          couponDataSource: val.couponDataSource,
        });
      }
      return res;
    }, []);
  }

  get tables(): {
    tableId: string;
    tableNo: string;
    lineUsers: GuestLog[];
  }[] {
    const activeTables = this.tablesStore.getTables;

    return Object.keys(activeTables)
      .map((key) => ({
        tableId: key,
        tableNo: activeTables[key].params.tableNo,
        lineUsers: activeTables[key].params.lineUsers,
      }))
      .filter((v) => v.lineUsers.length >= 1)
      .sort((a, b) => (a.tableNo > b.tableNo ? 1 : -1));
  }

  get labelSending(): string {
    if (!this.toLineUser) {
      return '表示されている全員に送信';
    }
    return this.toLineUser.length >= 2 ? `${this.toLineUser.length}名に送信` : this.toLineUser[0].lineUserName;
  }

  get selectQuestionnaireItems(): Array<{ text: string; value: string }> {
    return [
      { text: '', value: '' },
      ...this.questionnairesList.map((val) => ({ text: val.params.title, value: val.params.id })),
    ];
  }

  get isSomeContentEmpty(): boolean {
    return this.messageData.some(
      (message) =>
        message.text === '' &&
        (message.picture.filter((val) => val.type === this.URL_TYPE_INPUT && val.url.length === 0).length > 0 ||
          message.picture.filter((val) => val.type === this.URL_TYPE_SELECT && val.mediaId.length === 0).length > 0) &&
        (message.movieUrl === '' || message.movieThumbnailUrl === '') &&
        message.questionnaireId === '' &&
        (message.couponId === '' || message.couponName === '')
    );
  }

  get disabledSubmitBtn(): boolean {
    return (
      (this.toLineUser && this.to.length === 0 && this.toLineUser.length === 0) ||
      this.isSomeContentEmpty ||
      !this.isVaild
    );
  }

  get disabledSaveBtn(): boolean {
    return this.isSomeContentEmpty || !this.isVaild;
  }

  get selectPictureItems(): Array<{ text: string; value: string }> {
    return [
      { text: '', value: '' },
      ...this.mediaStore.getMedias
        .filter((val) => val.isPicture())
        .map((val) => ({ text: val.params.title, value: val.params.id })),
    ];
  }

  onTabChange(index: number): void {
    this.messageData[index] = {
      ...this.messageData[index],
      text: '',
      picture: [
        {
          type: this.URL_TYPE_INPUT,
          url: '',
          mediaId: '',
          linkUrl: '',
        },
      ],
      movieUrl: '',
      movieThumbnailUrl: '',
      questionnaireId: '',
      couponId: '',
      couponName: '',
    };
  }

  onUrlTypeChange(message: MessageData, pictureIndex: number): void {
    if (message.picture[pictureIndex].type === this.URL_TYPE_INPUT) {
      message.picture[pictureIndex].mediaId = '';
    } else if (message.picture[pictureIndex].type === this.URL_TYPE_SELECT) {
      message.picture[pictureIndex].url = '';
    }
  }

  /** Methods **/

  /** テーブル用 */
  open(to: string[] = []): void {
    this.display = true;
    this.process = 'send';
    this.toLineUser = [];
    this.to = to;
    this.messageData = [this.getEmptyMessageData()];
    this.tabNo = [0];
  }

  /** 返信用 */
  openReply(obj: LineUser[] | null): void {
    this.display = true;
    this.process = 'reply';
    this.to = [];
    this.toLineUser = obj;
    this.messageData = [this.getEmptyMessageData()];
    this.tabNo = [0];
  }

  /** 編集用 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  openEdit(type: messageAutomationType, messageData: MessageData[], option?: any): void {
    this.display = true;
    this.process = 'edit';
    this.type = type;
    this.messageData = messageData.length >= 1 ? messageData : [this.getEmptyMessageData()];
    this.tabNo = messageData.map((val) => this.getTabNoWithType(val.type)); // TODO:typeで表示タブを制御

    this.option = option;
  }

  getTabNoWithType(type: contentType): number {
    if (type === 'text') {
      return this.MESSAGE_TYPE_TEXT;
    } else if (type === 'image') {
      return this.MESSAGE_TYPE_PICTURE;
    } else if (type === 'video') {
      return this.MESSAGE_TYPE_MOVIE;
    } else if (type === 'questionnaire') {
      return this.MESSAGE_TYPE_QUESTIONNAIRE;
    } else if (type === 'coupon') {
      return this.MESSAGE_TYPE_COUPON;
    }

    return 0;
  }

  close(): void {
    // 初期化処理
    this.type = messageAutomationType.thanks;
    this.to = [];
    this.toLineUser = [];
    this.messageData = [this.getEmptyMessageData()];
    this.tabNo = [0];
    this.display = false;
  }

  onPushCloseBtn(): void {
    this.close();
  }

  onAddMessageData(): void {
    this.messageData.push(this.getEmptyMessageData());
    this.tabNo.push(0);
  }

  getEmptyMessageData(): MessageData {
    return {
      type: 'text',
      text: '',
      picture: [
        {
          type: this.URL_TYPE_INPUT,
          url: '',
          mediaId: '',
          linkUrl: '',
        },
      ],
      movieUrl: '',
      movieThumbnailUrl: '',
      questionnaireId: '',
      couponId: '',
      couponName: '',
      couponUseType: useType.oneTime,
      couponExpirationDays: null,
      couponDataSource: couponDataSource.internal,
    };
  }

  onDeleteMessageData(index: number): void {
    // messageDataが1つの場合は削除しない
    if (this.messageData.length === 1) {
      return;
    }

    this.messageData.splice(index, 1);
    this.tabNo.splice(index, 1);
  }

  disabledAddMessageBtn(): boolean {
    return this.messageData.length >= 3;
  }

  icon(): string {
    if (this.to.length === this.tables.length) {
      return 'check_box';
    } else {
      return 'check_box_outline_blank';
    }
  }

  // セレクトリストの全選択のコントロール
  toggle(): void {
    if (this.to.length === this.tables.length) {
      this.to = [];
      this.toTableNo = '';
    } else {
      this.to = this.tables.map((v) => v.tableId);
      this.toTableNo = 'all';
    }
  }

  /** 画像登録ダイアログ表示 */
  onPushAddPictureDialog(index: number, pictureIndex: number): void {
    this.dataIndex = index;
    this.pictureIndex = pictureIndex;
    this.addMediaDialog.open();
  }

  // 画像・動画ファイルの登録処理
  onPushAddMeiaDialogEnterBtn(param: AddMediaDialogParams): void {
    this.appStore.startLoading();
    this.mediaStore
      .addMedia({
        contractId: this.accountSetting.params.contractId,
        newImage: {
          oldUrl: '',
          url: param.src,
          file: param.file,
        },
        title: param.title,
      })
      .then((val) => {
        this.successDialog.open({
          message: '保存しました',
        });
        this.messageData[this.dataIndex].picture[this.pictureIndex].mediaId = val;
      })
      .catch((error) => this.errorPropagation(error))
      .finally(() => this.appStore.stopLoading());
  }

  /** 画像指定追加 */
  onPushAddPicture(index: number): void {
    if (this.messageData[index].picture.length >= 10) {
      alert('指定出来る画像は10個までです');
      return;
    }

    this.messageData[index].picture.push({
      type: this.URL_TYPE_INPUT,
      url: '',
      mediaId: '',
      linkUrl: '',
    });
  }

  /** 画像指定削除 */
  onPushDeletePicture(index: number, pictureIndex: number): void {
    // 画像指定が1つの場合は削除しない
    if (this.messageData[index].picture.length === 1) {
      return;
    }

    this.messageData[index].picture.splice(pictureIndex, 1);
  }

  /** 保存 */
  onPushSaveBtn(): void {
    const param: { type: messageAutomationType; messageData: LineMessageContent[] } = {
      type: this.type,
      messageData: this.lineMessageContent,
    };

    this.$emit('onPushSaveBtn', param, this.option);

    this.close();
  }

  /** LINEユーザ名を挿入 */
  onPushInsertUserName(text: string, index: number): void {
    const textarea = (this.$refs[`textarea${index}`] as TextareaHTMLAttributes)[0];
    textarea.focus();
    const textToInsert = CommonSettings.MESSAGE_BIND_KEY.USER_NAME;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const newText = text.slice(0, startPos) + textToInsert + text.slice(endPos);

    this.messageData[index].text = newText;

    // 入力完了後にフォーカスを戻す
    this.$nextTick(() => {
      textarea.setSelectionRange(startPos + textToInsert.length, startPos + textToInsert.length);
    });
  }

  /** メッセージの送信 **/
  onPushSubmitMessageBtn(): void {
    this.submitMessageConfirmationDialog.open();
  }

  onPushSubmitMessageConfirmationDialogEnterBtn(): void {
    // ユーザを指定しない場合はコンテンツを返すだけ
    if (!this.toLineUser) {
      this.$emit('onPushSubmitContents', this.lineMessageContent);
      return;
    }

    const lineUsers: LineUser[] = this.toLineUser.length
      ? this.toLineUser
      : this.to
          .flatMap((val) =>
            this.allTables[val].params.lineUsers.map((v) => ({
              lineUserId: v.lineUserID,
              lineUserName: v.displayName,
              pictureUrl: v.pictureUrl,
            }))
          )
          .filter(
            (val, index, self) =>
              self.findIndex((v) => v.lineUserId === val.lineUserId && v.lineUserName === val.lineUserName) === index
          ); // 重複除去

    const tableNos = this.toLineUser.length
      ? this.toLineUser.map((val) => val.lineUserName)
      : this.tables.filter((val) => this.to.includes(val.tableId)).map((val) => val.tableNo);

    this.messagesStore
      .sendLineMessage({
        contractId: this.accountSetting.params.contractId,
        message: this.lineMessageContent,
        lineUsers: lineUsers,
        tableNo: tableNos,
      })
      .catch((error) => {
        this.errorPropagation(error);
      });

    this.close();
  }

  updateCouponItem(coupon: CouponParams, index: number): void {
    this.messageData[index].couponId = coupon.id;
    this.messageData[index].couponName = coupon.name;
    this.messageData[index].couponUseType = coupon.useType;
    this.messageData[index].couponExpirationDays = coupon.expirationDays;
    this.messageData[index].couponDataSource = coupon.dataSource;
  }

  // mounted() {
  //   this.$refs['textarea0'].focus();
  // }

  // エラーの伝搬
  @Emit('error')
  errorPropagation(error: Error): Error {
    return error;
  }
}
</script>
