import { storeType } from '@/common/appCode';
import { RStore } from './db/r-store';
import { GeoLocation } from './db/common-models';

/** アプリケーションで用いるモデル **/
export type StoreModel = RStore;

export class Store {
  constructor(public params: StoreModel) {}

  /** Getter */
  get id(): string {
    return this.params.id;
  }

  get code(): string {
    return this.params.code;
  }

  get type(): storeType {
    return this.params.type;
  }

  get name(): string {
    return this.params.name;
  }

  get nameKana(): string {
    return this.params.nameKana ?? '';
  }

  get shortName(): string {
    return this.params.shortName;
  }

  get address(): string {
    return this.params.address;
  }

  get tel(): string {
    return this.params.tel;
  }

  get location(): GeoLocation {
    return this.params.location ?? { latitude: 0, longitude: 0 };
  }

  get sort(): number {
    return this.params.sort;
  }
}
