import { defineStore } from 'pinia';
import { StoreError } from '@/common/error';
import { api } from '@/firebase/api';
import { useUsersStore } from '@/stores/users';

export const useSmsAuthStore = defineStore('smsAuth', () => {
  const userStore = useUsersStore();

  /**
   * SMS認証のメッセージを送信
   *
   * @param obj
   */
  const send = async (obj: { contractId: string; to: string; authCode: string }): Promise<void> => {
    try {
      // 順番待ちの情報を更新
      await api.sendSmsAuth({
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        to: obj.to,
        authCode: obj.authCode,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return { send };
});
