<template>
  <v-dialog v-model="display" max-width="500px">
    <v-card title="スタッフ選択">
      <v-card-text class="text-center py-1">
        <v-select
          v-model="selectedStaffId"
          :items="selectStaffs"
          item-title="text"
          item-value="value"
          variant="outlined"
          density="compact"
          class="body"
        ></v-select>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-btn block variant="outlined" @click="close">閉じる</v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn block color="primary" variant="elevated" @click="changeStaff">設定</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Options } from 'vue-class-component';
import { Staff } from '@/store/models/staff';
import { Formattable } from '@/common/mixin/formattable';

@Options({})
export default class SelectStaffDialog extends Formattable {
  /** Props **/

  /** Data **/
  display = false;
  selectedStaffId = '';
  selectStaffs: Array<{ text: string; value: string }> = [
    {
      text: '選択解除',
      value: '',
    },
  ];

  /** Refs **/

  /** Computed **/

  /** Methods **/
  open(obj: { staffId: string; staffList: Staff[] }): void {
    this.display = true;
    this.selectedStaffId = obj.staffId;
    this.selectStaffs = [
      ...obj.staffList.map((staff) => ({ text: staff.params.name, value: staff.id })),
      { text: '選択解除', value: '' },
    ];
  }

  close(): void {
    this.display = false;
  }

  changeStaff(): void {
    this.$emit('changeStaff', this.selectedStaffId);
    this.close();
  }

  onPushCloseBtn(): void {
    this.close();
  }
}
</script>
