<template>
  <v-dialog v-model="display" persistent max-width="500px">
    <v-card title="パスワードを入力してください" :text="message">
      <v-card-title>
        <span class="text-h5"></span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          variant="outlined"
          density="comfortable"
          prepend-inner-icon="mdi:mdi-lock-outline"
          :append-inner-icon="showPassword ? 'visibility' : 'visibility_off'"
          @click:appendInner="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-col cols="6"><v-btn block variant="outlined" @click="onPushCancelBtn">キャンセル</v-btn></v-col>
        <v-col cols="6">
          <v-btn color="primary" :disabled="isDisabledEnterBtn()" block variant="elevated" @click="onPushEnterBtn"
            >OK</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Prop, Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

/** Interface **/
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PasswordDialogEnterBtnParams {
  password: string;
  data?: any;
}

@Options({})
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class PasswordDialog extends Vue {
  /** Data **/
  display = false;
  showPassword = true;
  data?: any = {};
  password = '';

  /** Props **/
  @Prop({ default: '' }) message!: string;

  /** Methods **/
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  open(data?: any): void {
    this.data = data;
    this.display = true;
  }

  close(): void {
    this.password = '';
    this.display = false;
  }

  isDisabledEnterBtn(): boolean {
    return !this.password;
  }

  onPushCancelBtn(): void {
    this.close();
  }

  @Emit('onPushEnterBtn')
  onPushEnterBtn(): PasswordDialogEnterBtnParams {
    const obj = {
      password: this.password,
      data: this.data,
    };
    this.close();
    return obj;
  }
}
</script>
