<template>
  <v-tooltip location="top">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" :color="color" :size="size" @click.stop>{{ icon }}</v-icon>
    </template>
    <span class="note">{{ text }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ToolTip extends Vue {
  /** Data **/

  @Prop({ default: '' }) icon!: string;
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) color!: string;
  @Prop({ default: '' }) size!: string;
}
</script>

<style scoped>
.note {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
