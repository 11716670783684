<template>
  <v-app>
    <!-- ログイン済みの場合にメニューを表示 -->
    <menu-bar
      v-if="alreadyLogin && alreadyStoreLogin"
      :pageTitle="pageTitle"
      :pageOrder="pageOrder"
      :appVersion="appVersion"
      @onPushItem="pageTransition"
      @onPushLogout="onPushLogout"
      @error="errorCapture"
    />
    <v-main v-if="alreadyLogin" :style="{ background: $vuetify.theme.themes.light.variables.background }">
      <router-view @error="errorCapture"></router-view>
    </v-main>
    <router-view v-if="!alreadyLogin" @error="errorCapture"></router-view>
    <!-- Dialog -->
    <error-dialog ref="errorDialog" />
    <loading-dialog ref="loadingDialog" :display="isLoading" :message="loadingMessage" />
    <password-dialog
      ref="passwordDialog"
      :message="'この画面へ遷移するためにはパスワードの入力が必要です。'"
      @onPushEnterBtn="onPushPasswordDialogEnterBtn"
    />
    <confirmation-dialog
      ref="logoutConfirmationDialog"
      :title="'ログアウト'"
      :message="'ログアウトしてもよろしいですか?'"
      @onPushEnterBtn="onPushLogOutConfirmationDialogEnterBtn"
    />
  </v-app>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import { Options } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import './common/scss/app-base.scss';
import { EnvConfig } from '@/common/env-config';
import * as Pages from '@/router/index';
import MenuBar from '@/components/organisms/MenuBar.vue';
import ErrorDialog from '@/components/molecules/ErrorDialog.vue';
import PasswordDialog, { PasswordDialogEnterBtnParams } from '@/components/molecules/PasswordDialog.vue';
import LoadingDialog from '@/components/molecules/LoadingDialog.vue';
import ConfirmationDialog from '@/components/molecules/ConfirmationDialog.vue';
import { useAppStore } from '@/stores/app';
import { useUsersStore } from '@/stores/users';
import { AuthorizationError } from './common/error';
import { Settings } from '@/store/models/settings';
import { Formattable } from './common/mixin/formattable';

@Options({
  components: {
    'loading-dialog': LoadingDialog,
    'error-dialog': ErrorDialog,
    'password-dialog': PasswordDialog,
    'confirmation-dialog': ConfirmationDialog,
    'menu-bar': MenuBar,
  },
})
export default class App extends Formattable {
  /** Data **/
  appStore = useAppStore();
  userStore = useUsersStore();

  pageOrder: Pages.PageInfo[] = Pages.pageOrder;
  pageTitle = '';

  /** Refs **/
  @Ref('errorDialog') errorDialog!: ErrorDialog;
  @Ref('passwordDialog') passwordDialog!: PasswordDialog;
  @Ref('logoutConfirmationDialog') logoutConfirmationDialog!: ConfirmationDialog;

  /** Computed **/

  get settings(): Settings {
    return this.userStore.getSettings;
  }

  get appVersion(): string {
    return EnvConfig.app.APP_VERSION;
  }

  get alreadyLogin(): boolean {
    return this.userStore.getLogin;
  }

  get alreadyStoreLogin(): boolean {
    return this.userStore.getStoreLogin;
  }

  get isLoading(): boolean {
    return this.appStore.getIsLoading;
  }

  get loadingMessage(): string {
    return this.appStore.getLoadingMessage;
  }

  /** Watchs **/
  @Watch('$route')
  onChangeRoute(to: { path: string; name: string }): void {
    console.log(to.path);
    this.pageTitle = to.name;
  }

  /** Methods **/

  /** 画面遷移 **/
  async pageTransition(page: Pages.PageInfo): Promise<void> {
    if (page.requireAuthorization && !this.accountSetting.passwordLockDisabled) {
      this.passwordDialog.open(page.route);
    } else if (page.openOtherWindow) {
      window.open(`${page.route}?contractId=${this.contractId}&storeId=${this.storeId}`, '_blank');
    } else if (this.$router.currentRoute.value.path !== page.route) {
      await this.$router.push(page.route);
    }
  }

  /** 認可 **/
  onPushPasswordDialogEnterBtn(obj: PasswordDialogEnterBtnParams): void {
    this.appStore.startLoading();
    this.userStore
      .reauthentication(obj)
      .then(() => this.appStore.stopLoading())
      .then(() => this.$router.push(obj.data))
      .catch((error) => {
        this.appStore.stopLoading();
        this.errorCapture(new AuthorizationError(error.code));
      });
  }

  /** ログアウト **/
  onPushLogout(): void {
    this.logoutConfirmationDialog.open();
  }

  onPushLogOutConfirmationDialogEnterBtn(): void {
    this.appStore.startLoading();
    this.userStore
      .logout()
      .catch((error) => this.errorCapture(error))
      .finally(() => {
        this.appStore.stopLoading();
        this.$router.push('/login');
      });
  }

  /** イベントの伝搬のハンドリング **/
  disablePinch(event: TouchEvent): void {
    if (event.touches.length >= 2) {
      event.preventDefault();
    }
  }

  /** ErrorのCatch **/
  errorCapture(error: Error): void {
    console.log(error);
    this.errorDialog.open({
      message: error.message as string,
    });
  }

  /** LifeCycle **/
  mounted(): void {
    // ピッチインピッチアウトによる拡大縮小を禁止
    document.addEventListener('touchstart', this.disablePinch, { capture: true, passive: false });
    // ネットワーク接続状況を監視
    setInterval(() => {
      if (!navigator.onLine) {
        this.errorDialog.open({
          message: 'インターネットに接続されていません',
        });
      }
    }, 10 * 1000);
  }

  unmounted(): void {
    window.removeEventListener('touchstart', this.disablePinch, false);
  }
}
</script>

<style>
html {
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}
body {
  box-sizing: border-box;
  -webkit-touch-callout: none;
}
</style>
