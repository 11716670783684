import { EnvConfig } from '@/common/env-config';
import { CApp, NotificationItem } from '@/store/models/db/c-app';

export type AppModel = CApp;

/** アプリケーションで使うモデル **/
export class App {
  constructor(public params: AppModel) {}

  get serverVersion(): string {
    return this.params.version;
  }

  get isCurrentVersion(): boolean {
    const serverVerStr = this.serverVersion.split('.').reduce((res, val) => `${res}${val.padStart(2, '0')}`);
    const localVerStr = EnvConfig.app.APP_VERSION.split('.').reduce((res, val) => `${res}${val.padStart(2, '0')}`);

    const server = parseInt(serverVerStr, 10);
    const local = parseInt(localVerStr, 10);
    return server <= local;
  }

  get notificationItems(): NotificationItem[] {
    return this.params?.notificationItems ?? [];
  }

  get releaseItems(): NotificationItem[] {
    return this.params?.releaseItems ?? [];
  }
}
