<template>
  <v-dialog v-model="display" scrollable max-width="600px">
    <v-card title="メッセージ：過去24時間">
      <template v-slot:append>
        <v-select
          v-model="messageNotify"
          label="通知音"
          :items="soundTypes"
          item-title="text"
          item-value="value"
          variant="outlined"
          density="compact"
        ></v-select>
      </template>
      <v-divider></v-divider>
      <v-card-text style="height: 70%">
        <message-list :messages="messages" @onPushReply="onPushReplyBtn" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-col cols="6"><v-btn block variant="outlined" @click="onPushCloseBtn">閉じる</v-btn></v-col>
        <v-col cols="6"
          ><v-btn block color="primary" variant="elevated" @click="onPushLineMessageBtn">メッセージ作成</v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Dialogs -->
  <line-message ref="lineMessage" @error="errorPropagation"></line-message>
</template>

<script lang="ts">
// OPTION: ジェネリクスを使うか要検討。

import { Prop, Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { CommonSettings } from '@/common/common-settings';
import MessageList from '@/components/organisms/MessageList.vue';
import LineMessage from '@/components/organisms/LineMessage.vue';
import { MessageTimeLine } from '@/store/models/common-sys-models';
import { LineUser } from '@/store/models/db/common-models';
import { useUsersStore } from '@/stores/users';

@Options({
  components: {
    'message-list': MessageList,
    'line-message': LineMessage,
  },
  emits: ['error'],
})
export default class MessageDialog extends Vue {
  /** Refs **/
  @Ref('lineMessage') lineMessage!: LineMessage;

  /** Data **/
  userStore = useUsersStore();
  display = false;
  soundTypes: Array<{
    text: string;
    value: string;
  }> = [
    {
      text: '通常',
      value: CommonSettings.FILE_NAME.MESSAGE_SOUND_1,
    },
    {
      text: 'メッセージが届きました',
      value: CommonSettings.FILE_NAME.MESSAGE_SOUND_2,
    },
    {
      text: '通知音オフ',
      value: CommonSettings.FILE_NAME.MESSAGE_SOUND_NONE,
    },
  ];
  soundType: string = '';

  /** Props **/
  @Prop({ default: [] }) messages!: MessageTimeLine[];

  get contractId(): string {
    return this.userStore.getUser?.uid ?? '';
  }

  get messageNotify(): string {
    this.soundType =
      this.soundType ||
      localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.MESSAGE_NOTIFICATION_SOUND) ||
      CommonSettings.FILE_NAME.MESSAGE_SOUND_1;
    return this.soundType;
  }

  set messageNotify(newValue: string) {
    this.soundType = newValue;
    localStorage.setItem(
      CommonSettings.WEB_STORAGE_KEY.MESSAGE_NOTIFICATION_SOUND,
      newValue || CommonSettings.FILE_NAME.MESSAGE_SOUND_1
    );
  }
  /** Methods **/
  open(): void {
    this.display = true;
  }

  close(): void {
    this.display = false;
  }

  onPushLineMessageBtn(): void {
    this.lineMessage.open();
    this.close();
  }

  onPushReplyBtn(item: LineUser): void {
    this.lineMessage.openReply([item]);
  }

  onPushCloseBtn(): void {
    this.close();
  }

  @Emit('error')
  errorPropagation(error: Error): Error {
    return error;
  }
}
</script>
