// 開発環境用の環境変数設定
import * as dotenv from 'dotenv';

dotenv.config();

export const EnvConfig = {
  app: {
    VUE_APP_MODE: process.env.VUE_APP_MODE,
    VUE_APP_TITLE: process.env.VUE_APP_TITLE,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    APP_VERSION: require('../../package.json').version,
    LOCAL_API_HOST: process.env.VUE_APP_LOCAL_API_HOST,
    TAKEOUT_LIGHT_LINE_CHANNEL_ID: process.env.VUE_APP_TAKEOUT_LIGHT_LINE_CHANNEL_ID,
    TAKEOUT_LINE_CHANNEL_ID: process.env.VUE_APP_TAKEOUT_LINE_CHANNEL_ID,
    ATTACH_REDIRECT_URI: process.env.VUE_APP_ATTACH_REDIRECT_URI,
    STRIPE_CLIENT_ID: process.env.VUE_APP_STRIPE_CLIENT_ID,
    STRIPE_API_KEY: process.env.VUE_APP_STRIPE_API_KEY,
    STRIPE_ACCOUNT_ID: process.env.VUE_APP_STRIPE_ACCOUNT_ID,
    APP_HOST: process.env.VUE_APP_HOST,
    SONY_PAYMENT_REDIRECT_URL: process.env.VUE_APP_SONY_PAYMENT_REDIRECT_URL,
    SONY_PAYMENT_CORS_ORIGIN: process.env.VUE_APP_SONY_PAYMENT_CORS_ORIGIN,
    SONY_PAYMENT_PAYPAY_SETTLEMENT: process.env.VUE_APP_SONY_PAYMENT_PAYPAY_SETTLEMENT,
    SQUARE_CLIENT_ID: process.env.VUE_APP_SQUARE_CLIENT_ID,
    SQUARE_OAUTH_URL: process.env.VUE_APP_SQUARE_OAUTH_URL,
    SUKESAN_UID: process.env.VUE_APP_SUKESAN_UID,
    EP_TEST_UID: process.env.VUE_APP_EP_TEST_UID,
  },
  firebase: {
    API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    DATABASE_URL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    FUNCTIONS_REGION: process.env.VUE_APP_FIREBASE_FUNCTIONS_REGION,
    FIREBASE_VAPID_KEY: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    FIREBASE_APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,
  },
};
