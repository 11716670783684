import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_slide_group_item = _resolveComponent("v-slide-group-item")!
  const _component_v_slide_group = _resolveComponent("v-slide-group")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "blue-lighten-4",
    rounded: "sm",
    flat: "",
    class: "py-3 px-1",
    height: "100%"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_avatar, { size: "34" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, { src: _ctx.avatarImgUrl }, null, 8, ["src"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "11" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageContents, (item, index) => {
                return (_openBlock(), _createBlock(_component_v_card_text, {
                  key: index,
                  class: "py-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      rounded: "lg",
                      border: "",
                      variant: "flat"
                    }, {
                      default: _withCtx(() => [
                        (item.type === 'text')
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 0,
                              class: "message pa-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.message), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : (item.type === 'image' && !item.picture)
                            ? (_openBlock(), _createBlock(_component_v_img, {
                                key: 1,
                                cover: "",
                                src: item.originalUrl
                              }, null, 8, ["src"]))
                            : (item.type === 'image' && item.picture && item.picture.length === 1)
                              ? (_openBlock(), _createBlock(_component_v_img, {
                                  key: 2,
                                  cover: "",
                                  src: _ctx.getImageUrl(item.picture[0])
                                }, null, 8, ["src"]))
                              : (item.type === 'image' && item.picture && item.picture.length > 1)
                                ? (_openBlock(), _createBlock(_component_v_slide_group, {
                                    key: 3,
                                    "show-arrows": ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.picture, (picture, index) => {
                                        return (_openBlock(), _createBlock(_component_v_slide_group_item, { key: index }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card, {
                                              class: "mr-2",
                                              width: "200"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_img, {
                                                  cover: "",
                                                  eager: "",
                                                  src: _ctx.getImageUrl(picture)
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (item.type === 'video')
                                  ? (_openBlock(), _createBlock(_component_v_img, {
                                      key: 4,
                                      src: item.thumbnailUrl
                                    }, null, 8, ["src"]))
                                  : (item.type === 'questionnaire')
                                    ? (_openBlock(), _createBlock(_component_v_card_text, { key: 5 }, {
                                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                                          _createTextVNode("アンケート")
                                        ])),
                                        _: 1
                                      }))
                                    : (item.type === 'coupon')
                                      ? (_openBlock(), _createBlock(_component_v_card_text, {
                                          key: 6,
                                          class: "message pa-2"
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[1] || (_cache[1] = _createTextVNode("新しいクーポンが付与されました！会員証アプリでご確認いただけます")),
                                            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                                            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                                            _createTextVNode(_toDisplayString(item.couponName), 1),
                                            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                                            _cache[6] || (_cache[6] = _createTextVNode("https://miniapp.line.me/会員証URL"))
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            class: "text-caption mx-2 py-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messageContents.length === 0 ? 'メッセージ未登録' : '※ 実際のLINE画面の表示とは異なります'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}