import { FunctionsErrorCode } from 'firebase/functions';

export class UploadInvalidFile implements Error {
  name = 'UploadInvalidFile';
  message: string;
  constructor(validExtensions: string) {
    this.message = `${validExtensions}以外の形式のファイルがアップロードされました。\n${validExtensions}ファイルをアップロードしてください。`;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class APIError implements Error {
  name = 'APIError';
  message: string;
  constructor(type: FunctionsErrorCode, message: string) {
    const errorMap = {
      'functions/internal': 'ネットワークエラーです。接続に問題がないかご確認ください。',
      'functions/permission-denied': 'サーバに接続する権限がありません。サポートにお問い合わせください。',
      'functions/deadline-exceeded': 'タイムアウトしました。もう一度操作し直してください。',
      'functions/unknown': '不明なエラーが発生しました。サポートにお問い合わせください。',
      'functions/data-loss': '内部データに問題があります。サポートにお問い合わせください。',
    };
    this.message = errorMap[type] ?? message;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class MaximumNumberOfRegisteredError implements Error {
  name = 'MaximumNumberOfRegisteredError';
  message: string;
  constructor(dataName: string, maximumNumber: number) {
    this.message = `${dataName}の登録数が最大値(${maximumNumber})に達しているため、登録できません。`;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class NecessaryDataDoesNotExist implements Error {
  name = 'NecessaryDataDoesNotExist';
  message: string;
  constructor(dataName: string) {
    this.message = `${dataName}が存在しません。`;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class StatusError implements Error {
  name = 'StatusError';
  message: string;
  constructor(statusName: string, status: string) {
    this.message = `${statusName}は${status}です。`;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class ExistRelatedData implements Error {
  name = 'ExistRelatedData';
  message: string;
  constructor(dataName: string, dataText?: string) {
    this.message = `${dataName}が存在するため、処理を行うことができませんでした。 ${dataText ? dataText : ''}`;
  }
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class DataConflict implements Error {
  name = 'DataConflict';
  message = 'データの競合が発生しました。';
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class DocumentationDoesNotExist implements Error {
  name = 'DocumentationDoesNotExists';
  message = 'ドキュメントが存在しませんでした。';
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

export class UnavailableEnvironment implements Error {
  name = 'UnavailableEnvironment';
  message = 'この環境では利用できません。';
  toString(): string {
    return this.name + ': ' + this.message;
  }
}

// Storeで発生したエラー全般をラップするエラー
export class StoreError implements Error {
  name = 'StoreError';
  type: string;
  message: string;
  constructor(
    type: unknown, // 発生したエラーのname
    message: unknown
  ) {
    this.type = typeof type === 'string' ? type : 'Unknown';
    this.message = typeof message === 'string' ? message : '予期しないエラーが発生しました。';
  }
  toString(): string {
    return this.name + '\n' + this.type + ': ' + this.message;
  }
}

// 認証で発生したエラー全般をラップするエラー
export class AuthorizationError implements Error {
  name = 'AuthorizationError';
  type: string;
  message: string;
  constructor(
    type: unknown // 発生したエラーのname
  ) {
    this.type = typeof type === 'string' ? type : 'Unknown';

    const errorMap = {
      // case 'auth/email-already-in-use':
      //   if (method.indexOf('signup') !== -1) {
      //     return 'このメールアドレスは使用されています';
      //   } else {
      //     return 'メールアドレスまたはパスワードが違います';
      //   }
      'auth/invalid-email':
        'メールアドレスの形式が正しくありません。スペースが入っていないか、@が＠（全角）になっていないかなどをご確認ください',
      'auth/user-disabled': 'サービスの利用が停止されています',
      'auth/user-not-found': 'メールアドレスまたはパスワードが違います',
      // case 'auth/user-mismatch':
      //   if (method === 'signin/popup') {
      //     return '認証されているユーザーと異なるアカウントが選択されました';
      //   } else {
      //     return 'メールアドレスまたはパスワードが違います';
      //   }
      'auth/weak-password': 'パスワードは6文字以上で設定してください',
      'auth/wrong-password': 'メールアドレスまたはパスワードが違います',
      'auth/popup-blocked':
        '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください',
      'auth/operation-not-supported-in-this-environment': '現在この認証方法はご利用頂けません',
      'auth/auth-domain-config-required': '現在この認証方法はご利用頂けません',
      'auth/operation-not-allowed': '現在この認証方法はご利用頂けません',
      'auth/unauthorized-domain': '現在この認証方法はご利用頂けません',
      'auth/requires-recent-login': '認証の有効期限が切れています',
    };

    this.message = errorMap[this.type] ?? '認証に失敗しました。しばらく時間をおいて再度お試しください';
  }
  toString(): string {
    return this.name + '\n' + this.type + ': ' + this.message;
  }
}
