<template>
  <v-dialog v-model="display" persistent max-width="500px">
    <v-card :title="title" class="note">
      <v-card-text class="overflow-text">{{ message }}</v-card-text>
      <v-card-actions>
        <v-col v-if="!hideNgBtn" cols="6"
          ><v-btn block variant="outlined" @click="onPushCancelBtn">{{ ngBtnLable }}</v-btn></v-col
        >
        <v-col :cols="hideNgBtn ? 12 : 6"
          ><v-btn color="primary" variant="elevated" block @click="onPushEnterBtn">{{ okBtnLable }}</v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// OPTION: ジェネリクスを使うか要検討。

import { Prop, Emit } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ConfirmationDialog extends Vue {
  /** Data **/
  display = false;
  params: unknown; // 確定ボタン押下時に親側のイベントで使いたいデータ

  /** Props **/
  @Prop({ default: '' }) title!: string;
  @Prop({ default: '' }) message!: string;
  @Prop({ default: false }) hideNgBtn!: boolean;
  @Prop({ default: 'キャンセル' }) ngBtnLable!: string;
  @Prop({ default: 'OK' }) okBtnLable!: string;

  /** Methods **/
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  open(data?: unknown): void {
    this.display = true;
    this.params = data; // 確定ボタンを押下した際に用いる情報
  }

  close(): void {
    this.display = false;
  }

  onPushCancelBtn(): void {
    this.close();
  }

  @Emit('onPushEnterBtn')
  onPushEnterBtn(): unknown {
    this.close();
    return this.params;
  }
}
</script>
<style scoped>
.note {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.overflow-text {
  max-height: 200px;
  overflow-y: auto;
}
</style>
