import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  // stateはref変数
  const isLoading = ref(false);
  const loadingMessage = ref('');

  // gettterはcomputed
  const useComputables = () => {
    return {
      getIsLoading: computed(() => isLoading.value),
      getLoadingMessage: computed(() => loadingMessage.value),
    };
  };
  const computables = useComputables();

  // actionはmutationと統合して、関数
  const startLoading = (pLoadingMessage: string = '') => {
    console.log('startLoading.');
    isLoading.value = true;
    loadingMessage.value = pLoadingMessage;
  };

  const stopLoading = () => {
    console.log('stopLoading.');
    isLoading.value = false;
    loadingMessage.value = '';
  };

  return { ...computables, startLoading, stopLoading };
});
